import React from 'react';
import { motion } from 'framer-motion';
import { Bot, Zap, Brain, Rocket, Star, Shield, Code2, MessageCircle } from 'lucide-react';

const WelcomeSection = ({ theme, onStartChat, onExploreAgents }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const glowEffect = {
    hidden: { opacity: 0.5, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  };

  return (
    <motion.div 
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="w-full mb-16"
    >
      {/* Hero Section */}
      <motion.div 
        variants={itemVariants}
        className="text-center mb-12"
      >
        <motion.div
          animate={{ scale: [1, 1.02, 1] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="inline-flex items-center gap-3 px-4 py-1.5 rounded-full mb-6"
          style={{ 
            background: `linear-gradient(135deg, ${theme.colors.primary}20, ${theme.colors.secondary}20)`,
            border: `1px solid ${theme.colors.primary}30`
          }}
        >
          <Rocket className="h-4 w-4" style={{ color: theme.colors.primary }} />
          <span className="text-sm font-medium" style={{ color: theme.colors.primary }}>
            Experience the Future of Coding
          </span>
        </motion.div>
        <h1 
          className="text-4xl md:text-5xl font-bold mb-4"
          style={{ color: theme.colors.primary }}
        >
          Welcome to{" "}
          <span style={{ 
            background: `-webkit-linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.secondary})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }} className="font-spac3">
            CodeAI
          </span>
        </h1>
        <p className="text-xl max-w-2xl mx-auto mb-6" style={{ color: theme.colors.text }}>
          Your intelligent coding companion powered by advanced AI models and specialized agents
        </p>
      </motion.div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
        {/* AI Models Card */}
        <motion.div
          variants={itemVariants}
          className="rounded-2xl p-6 relative overflow-hidden"
          style={{ 
            backgroundColor: theme.colors.surface,
            border: `1px solid ${theme.colors.border}`,
          }}
        >
          <motion.div
            variants={glowEffect}
            className="absolute inset-0"
            style={{
              background: `radial-gradient(circle at 50% 50%, ${theme.colors.primary}10 0%, transparent 70%)`
            }}
          />
          
          <div className="relative">
            <div className="flex items-center gap-3 mb-6">
              <Brain className="h-6 w-6" style={{ color: theme.colors.primary }} />
              <h2 className="text-xl font-bold" style={{ color: theme.colors.primary }}>
                Powerful AI Models
              </h2>
            </div>

            <div className="space-y-4">
              <div className="flex items-start gap-4 p-3 rounded-xl transition-all duration-200 hover:scale-[1.02]"
                style={{ backgroundColor: theme.colors.background + '80' }}>
                <div className="mt-1">
                  <Zap className="h-5 w-5" style={{ color: theme.colors.secondary }} />
                </div>
                <div>
                  <h3 className="font-semibold mb-1" style={{ color: theme.colors.primary }}>Claude 3.5 Sonnet</h3>
                  <p className="text-sm" style={{ color: theme.colors.text + '90' }}>
                    Blazing-fast responses with deep coding knowledge and real-time pair programming
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4 p-3 rounded-xl transition-all duration-200 hover:scale-[1.02]"
                style={{ backgroundColor: theme.colors.background + '80' }}>
                <div className="mt-1">
                  <Star className="h-5 w-5" style={{ color: theme.colors.secondary }} />
                </div>
                <div>
                  <h3 className="font-semibold mb-1" style={{ color: theme.colors.primary }}>Claude 3 Opus</h3>
                  <p className="text-sm" style={{ color: theme.colors.text + '90' }}>
                    Advanced reasoning and code generation with unmatched accuracy and depth
                  </p>
                </div>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onStartChat}
                className="w-full mt-4 flex items-center justify-center gap-2 py-2.5 rounded-xl font-medium transition-all duration-200"
                style={{ 
                  background: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
                  color: theme.colors.ctaText,
                }}
              >
                <MessageCircle className="w-4 h-4" />
                Start Chatting
              </motion.button>
            </div>
          </div>
        </motion.div>

    

        {/* Agents Card */}
        <motion.div
          variants={itemVariants}
          className="rounded-2xl p-6 relative overflow-hidden"
          style={{ 
            backgroundColor: theme.colors.surface,
            border: `1px solid ${theme.colors.border}`,
          }}
        >
          <div className="absolute top-4 right-4">
            <motion.div
              animate={{ scale: [1, 1.1, 1] }}
              transition={{ duration: 1.5, repeat: Infinity }}
              className="px-3 py-1 rounded-full text-xs font-semibold"
              style={{ 
                backgroundColor: theme.colors.primary + '20',
                color: theme.colors.primary 
              }}
            >
              NEW
            </motion.div>
          </div>

          <div className="relative">
            <div className="flex items-center gap-3 mb-6">
              <Bot className="h-6 w-6" style={{ color: theme.colors.primary }} />
              <h2 className="text-xl font-bold" style={{ color: theme.colors.primary }}>
                Specialized AI Agents
              </h2>
            </div>

            <div className="space-y-4">
              <div className="flex items-start gap-4 p-3 rounded-xl transition-all duration-200 hover:scale-[1.02]"
                style={{ backgroundColor: theme.colors.background + '80' }}>
                <div className="mt-1">
                  <Code2 className="h-5 w-5" style={{ color: theme.colors.secondary }} />
                </div>
                <div>
                  <h3 className="font-semibold mb-1" style={{ color: theme.colors.primary }}>Full-Stack Expert</h3>
                  <p className="text-sm" style={{ color: theme.colors.text + '90' }}>
                    Specialized in both frontend and backend development, with deep framework knowledge
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4 p-3 rounded-xl transition-all duration-200 hover:scale-[1.02]"
                style={{ backgroundColor: theme.colors.background + '80' }}>
                <div className="mt-1">
                  <Shield className="h-5 w-5" style={{ color: theme.colors.secondary }} />
                </div>
                <div>
                  <h3 className="font-semibold mb-1" style={{ color: theme.colors.primary }}>DevSecOps Master</h3>
                  <p className="text-sm" style={{ color: theme.colors.text + '90' }}>
                    Expert in security, infrastructure, and deployment automation
                  </p>
                </div>
              </div>
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onExploreAgents}
            className="w-full mt-4 flex items-center justify-center gap-2 py-2.5 rounded-xl font-medium transition-all duration-200"
            style={{ 
              background: `linear-gradient(135deg, ${theme.colors.secondary}, ${theme.colors.secondary}90)`,
              color: theme.colors.ctaText,
            }}
          >
            <Bot className="w-4 h-4" />
            Explore Agents
          </motion.button>
        </motion.div>
      </div>

      {/* Getting Started Banner */}
      <motion.div
        variants={itemVariants}
        className="rounded-2xl p-8 text-center relative overflow-hidden"
        style={{ 
          background: `linear-gradient(135deg, ${theme.colors.primary}15, ${theme.colors.secondary}15)`,
          border: `1px solid ${theme.colors.border}`,
        }}
      >
        <motion.div
          animate={{
            backgroundPosition: ['0% 50%', '100% 50%', '0% 50%']
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `linear-gradient(45deg, transparent, ${theme.colors.primary}, transparent)`,
            backgroundSize: '200% 200%'
          }}
        />
        
        <div className="relative">
          <h2 className="text-2xl font-bold mb-4" style={{ color: theme.colors.primary }}>
            Ready to Supercharge Your Coding?
          </h2>
          <p className="text-lg max-w-2xl mx-auto" style={{ color: theme.colors.text }}>
            Try our expertly crafted prompts below or start a conversation with your own question!
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default WelcomeSection;