import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  MenuIcon,
  ChevronDownIcon,
  PlusIcon,
  LogoutIcon,
  LockClosedIcon,
  SparklesIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { models } from "../utils/models";
import { logout, clearHistory, switchModel } from "./store/slices/authSlice";
import { CheckIcon } from "lucide-react";
import { useTheme } from "../utils/themeProvider";
import { ModelDisplay, ModelDropdown } from "./ModelDropdown";
import { fetchFavoriteAgents } from "./store/slices/agentsSlice";
import { useNavigate } from "react-router-dom";

const Header = ({ toggleSidebar, toggleUpgradeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const {
    token,
    user,
    settings = {},
    currentModel,
    requestsRemaining,
  } = useSelector((state) => state.auth);

  const { favoriteAgents, isLoading: isAgentsLoading } = useSelector(
    (state) => state.agents
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchFavoriteAgents(token));
    }
  }, [dispatch, token]);

  const defaultSettings = {
    plan: "Free",
    selectedModel: {
      name: "gpt-3.5",
      id: "gpt-3.5-turbo",
      type: "openai",
    },
    requestsRemaining: 0,
  };
  const safeSettings = {
    ...defaultSettings,
    ...settings,
  };

  const safeCurrentModel = typeof(currentModel) === 'string' ? defaultSettings.selectedModel : currentModel;
  const safeRequestsRemaining =
    requestsRemaining ?? defaultSettings.requestsRemaining;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [modelDropdownVisible, setModelDropdownVisible] = useState(false);
  const userDropdownRef = useRef(null);
  const modelDropdownRef = useRef(null);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleModelDropdown = () =>
    setModelDropdownVisible(!modelDropdownVisible);

  const handleClickOutside = (event) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
    if (
      modelDropdownRef.current &&
      !modelDropdownRef.current.contains(event.target)
    ) {
      setModelDropdownVisible(false);
    }
  };

  const handleNewChat = () => {
    dispatch(clearHistory());
    navigate("/");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (!token) dispatch(logout());
  }, [token, dispatch]);

  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  const isFreePlan = ["free", "explorer"].includes(
    safeSettings.plan.toLowerCase()
  );

  const handleModelSwitch = (modelOrAgent) => {
    if (
      isFreePlan &&
      !modelOrAgent.assistantId &&
      modelOrAgent.name.toLowerCase() !== "gpt-3.5"
    ) {
      toggleUpgradeModal();
    } else {
      let modelConfig;

      if (modelOrAgent.assistantId) {
        // It's an agent
        modelConfig = {
          name: modelOrAgent.name,
          id: `assistants-${modelOrAgent.assistantId}`,
          type: "assistant",
          instructions: modelOrAgent.instructions,
        };
      } else {
        // It's a regular model
        modelConfig = {
          name: modelOrAgent.name,
          id: modelOrAgent.name.toLowerCase().includes("gpt")
            ? modelOrAgent.name.toLowerCase()
            : modelOrAgent.name.toLowerCase(),
          type: modelOrAgent.name.toLowerCase().includes("gpt")
            ? "openai"
            : modelOrAgent.name.toLowerCase().includes("claude")
            ? "anthropic"
            : "openai",
        };
      }

      dispatch(switchModel({ token, model: modelConfig }));
      toggleModelDropdown();
    }
  };

  const renderUpgradeButton = () => (
    <motion.button
      variants={buttonVariants}
      initial="rest"
      whileHover="hover"
      whileTap="tap"
      onClick={() => {
        toggleDropdown();
        toggleUpgradeModal();
      }}
      style={{
        background: `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.accent})`,
        color: theme.colors.text,
      }}
      className="w-full rounded-lg px-4 py-2 text-sm font-medium transition duration-200 flex items-center justify-center shadow-md"
    >
      <SparklesIcon className="h-4 w-4 mr-2" />
      <span>Upgrade to Pro</span>
    </motion.button>
  );

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      style={{
        background: `linear-gradient(to right, ${theme.colors.background}, ${theme.colors.surface})`,
        borderColor: theme.colors.hover,
      }}
      className="sticky top-0 z-50 backdrop-blur-xl border-b shadow-lg"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            {user && (
              <motion.button
                variants={buttonVariants}
                initial="rest"
                whileHover="hover"
                whileTap="tap"
                onClick={toggleSidebar}
                style={{
                  backgroundColor: theme.colors.surface,
                  color: theme.colors.text,
                }}
                className="mr-4 p-2 rounded-full hover:bg-opacity-80 transition-all duration-200 transform hover:scale-105"
              >
                <MenuIcon className="h-5 w-5" />
              </motion.button>
            )}
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              style={{
                color: theme.colors.primary,
              }}
              className="font-spac3 text-xl md:text-2xl font-bold"
            >
              CodeAI
            </motion.h2>
          </div>
          <div className="flex items-center space-x-2 sm:space-x-4">
            {user && (
              <motion.button
                variants={buttonVariants}
                initial="rest"
                whileHover="hover"
                whileTap="tap"
                onClick={handleNewChat}
                style={{
                  background: `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`,
                  color: theme.colors.text,
                }}
                className="rounded-full px-2 py-2 sm:px-4 sm:py-2 text-sm font-medium transition-all duration-200 flex items-center shadow-md transform hover:scale-105"
              >
                <PlusIcon className="h-4 w-4 sm:mr-2" />
                <span className="hidden sm:inline">New Chat</span>
              </motion.button>
            )}
            {settings ? (
              <div className="relative" ref={modelDropdownRef}>
                <motion.button
                  variants={buttonVariants}
                  initial="rest"
                  whileHover="hover"
                  whileTap="tap"
                  onClick={toggleModelDropdown}
                  style={{
                    background: `linear-gradient(135deg, ${theme.colors.surface}, ${theme.colors.background})`,
                    color: theme.colors.text,
                    boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
                  }}
                  className="rounded-full px-3 py-2 sm:px-4 sm:py-2 text-sm font-medium transition-all duration-300 flex items-center hover:shadow-xl"
                >
                  <ModelDisplay model={safeCurrentModel} theme={theme} />
                  <motion.div
                    animate={{ rotate: modelDropdownVisible ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ChevronDownIcon className="h-4 w-4 ml-1 sm:ml-2 opacity-70" />
                  </motion.div>
                </motion.button>
                <AnimatePresence>
                  {modelDropdownVisible && (
                    <ModelDropdown
                      isVisible={modelDropdownVisible}
                      onClose={() => setModelDropdownVisible(false)}
                      onModelSelect={handleModelSwitch}
                      currentModel={safeCurrentModel}
                      favoriteAgents={favoriteAgents?.assistants || []}
                      isLoading={isAgentsLoading}
                      isFreePlan={isFreePlan}
                      theme={theme}
                      toggleUpgradeModal={toggleUpgradeModal}
                    />
                  )}
                </AnimatePresence>
              </div>
            ) : (
              renderUpgradeButton()
            )}
  {user && (
  <div className="relative" ref={userDropdownRef}>
    <motion.button
      variants={buttonVariants}
      initial="rest"
      whileHover="hover"
      whileTap="tap"
      onClick={toggleDropdown}
      style={{
        background: `linear-gradient(135deg, ${theme.colors.surface}, ${theme.colors.background})`,
        color: theme.colors.text,
        boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
      }}
      className="rounded-full px-2 py-3 sm:px-4 sm:py-3 text-sm font-medium transition-all duration-200 flex items-center shadow-md hover:shadow-xl"
    >
      <UserCircleIcon className="h-4 w-4 sm:mr-2" style={{ color: theme.colors.text }} />
      <span className="hidden sm:inline">
        {user ? `${user.first_name || ""} ${user.last_name || ""}` : "Guest User"}
      </span>
      <motion.div
        animate={{ rotate: dropdownVisible ? 180 : 0 }}
        transition={{ duration: 0.2 }}
      >
        <ChevronDownIcon className="h-4 w-4 ml-1 sm:ml-2 opacity-70" />
      </motion.div>
    </motion.button>

    <AnimatePresence>
      {dropdownVisible && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          style={{
            background: theme.colors.surface,
            borderColor: theme.colors.hover + '40',
            boxShadow: `0 20px 25px -5px ${theme.colors.primary}20, 0 10px 10px -5px ${theme.colors.hover}30`,
            color: theme.colors.text,
          }}
          className="absolute right-0 mt-2 w-72 rounded-xl border overflow-hidden"
        >
          <div className="p-4">
            {/* User Info Section */}
            <div className="flex flex-col space-y-2">
              <span
                className="text-xs uppercase tracking-wider font-semibold"
                style={{ color: `${theme.colors.text}80` }}
              >
                Current Plan
              </span>
              <div className="flex items-center justify-between">
                <span
                  className="font-semibold text-lg"
                  style={{ color: theme.colors.text }}
                >
                  {safeSettings.plan}
                </span>
                <span
                  className="px-3 py-1 rounded-full text-xs font-bold"
                  style={{
                    backgroundColor: `${theme.colors.primary}30`,
                    color: theme.colors.primary,
                  }}
                >
                  {safeRequestsRemaining} requests left
                </span>
              </div>
            </div>

            {/* Divider */}
            <div 
              className="my-4" 
              style={{ borderColor: theme.colors.hover + '20', borderTopWidth: '1px' }} 
            />

            {/* User Details */}
            <div className="flex items-center space-x-3 mb-4">
              <div 
                className="p-2 rounded-lg"
                style={{ backgroundColor: theme.colors.hover + '20' }}
              >
                <UserCircleIcon className="h-5 w-5" style={{ color: theme.colors.primary }} />
              </div>
              <div>
                <div className="font-medium">
                  {user ? `${user.first_name} ${user.last_name}` : "Guest User"}
                </div>
                <div className="text-sm" style={{ color: theme.colors.text + '60' }}>
                  {user?.email}
                </div>
              </div>
            </div>

            {isFreePlan && (
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                style={{
                  background: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
                }}
                className="w-full px-4 py-2 rounded-lg text-white text-sm font-medium shadow-md hover:shadow-lg transition-all duration-300 mb-4"
                onClick={() => {
                  toggleDropdown();
                  toggleUpgradeModal();
                }}
              >
                <span className="flex items-center justify-center gap-2">
                  <SparklesIcon className="h-4 w-4" />
                  Upgrade Plan
                </span>
              </motion.button>
            )}

            {/* Logout Button */}
            <motion.button
              whileHover={{ backgroundColor: theme.colors.hover + '20' }}
              onClick={() => {
                dispatch(logout());
                toggleDropdown();
              }}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 rounded-lg text-sm transition-all duration-200"
            >
              <LogoutIcon className="h-4 w-4" style={{ color: theme.colors.text + '70' }} />
              <span className="font-medium">Log Out</span>
            </motion.button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </div>
)}
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
