import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authenticate } from "./store/slices/authSlice";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { io } from "socket.io-client";

const TextField = ({ label, id, ...props }) => (
  <div className="relative">
    <input
      {...props}
      id={id}
      className="peer w-full rounded-lg border border-gray-700 bg-gray-800/50 px-4 py-3.5 pl-4 text-white placeholder-transparent focus:border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500/20"
      placeholder={label}
    />
    <label
      htmlFor={id}
      className="absolute -top-2.5 left-2 bg-gray-900 px-1 text-sm text-gray-400 transition-all peer-placeholder-shown:left-4 peer-placeholder-shown:top-3.5 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-sm peer-focus:text-purple-500"
    >
      {label}
    </label>
  </div>
);

const Button = ({ className = "", children, ...props }) => (
  <button
    {...props}
    className={`relative inline-flex w-full items-center justify-center overflow-hidden rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 p-[1px] font-medium text-white transition-all duration-300 hover:from-purple-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-purple-500/20 disabled:cursor-not-allowed disabled:opacity-60 ${className}`}
  >
    <span className="relative w-full rounded-lg bg-gray-900 px-4 py-3 transition-all duration-300 group-hover:bg-opacity-0">
      {children}
    </span>
  </button>
);

function EmptyState() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const [googleSignInLoading, setGoogleSignInLoading] = useState(false);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const socket = useRef(null);
  const socketTimeoutRef = useRef(null);
  const sessionId = useRef(uuidv4());

  const initializeSocket = () => {
    if (socket.current) {
      socket.current.disconnect();
    }

    if (socketTimeoutRef.current) {
      clearTimeout(socketTimeoutRef.current);
    }

    socket.current = io("https://api.codeai.studio", {
      query: { sessionId: sessionId.current },
    });

    socket.current.emit("registerSession", {
      sessionId: sessionId.current,
    });

    socket.current.on("auth-success", async (data) => {
      if (data.token) {
        setGoogleSignInLoading(false);
        localStorage.setItem("token", data.token);
        dispatch(authenticate(data.token));
        window.location.href = "/";
      }
    });

    socketTimeoutRef.current = setTimeout(
      () => {
        if (socket.current) {
          socket.current.disconnect();
          setError("Authentication timeout. Please try again.");
          setGoogleSignInLoading(false);
        }
      },
      5 * 60 * 1000
    ); // 5 minutes
  };

  const handleGoogleSignIn = () => {
    setGoogleSignInLoading(true);
    initializeSocket();
    const url = `https://api.codeai.studio/api/auth/google?sessionId=${sessionId.current}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    } else {
      setError(
        "Failed to open Google Sign-In window. Please check your pop-up blocker settings."
      );
      setGoogleSignInLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
      if (socketTimeoutRef.current) {
        clearTimeout(socketTimeoutRef.current);
      }
    };
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsAnimating(true);

    try {
      const response = await axios.post(
        "https://api.codeai.studio/api/auth/login",
        { email, password }
      );

      const token = response.data.jwt;

      // Dispatch Redux authenticate action
      await dispatch(authenticate(token));
    } catch (error) {
      console.error("Sign in failed:", error);
      setError(
        "Failed to sign in. Please check your credentials and try again."
      );
    } finally {
      setLoading(false);
      setIsAnimating(false);
    }
  };

  return (
    <div className="relative min-h-screen bg-gray-900 pt-20 flex flex-col items-center justify-center">
      {/* Animated background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-4 top-8 h-[500px] w-[500px] rounded-full bg-purple-500/10 blur-3xl animate-pulse" />
        <div className="absolute right-0 top-0 h-[500px] w-[500px] rounded-full bg-indigo-500/10 blur-3xl animate-pulse" />
      </div>

      {/* CodeAI Logo */}
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="mb-8"
      >
        <h1 className="text-6xl font-spac3 font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
          CodeAI App
        </h1>
      </motion.div>

      <AnimatePresence>
        {!isAnimating ? (
          <motion.div
            key="form"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="relative w-full max-w-lg px-4 py-8 sm:px-6 lg:px-8"
          >
            <div className="overflow-hidden rounded-2xl border border-gray-800 bg-gray-900/80 p-8 shadow-2xl backdrop-blur-xl">
              <div className="mb-6 text-center">
                <h2 className="text-3xl font-bold tracking-tight text-white">
                  Welcome Back!
                </h2>
                <p className="mt-2 text-sm leading-6 text-gray-300">
                  Sign in to your account and continue your journey.
                </p>
              </div>

              <form onSubmit={handleSignIn} className="space-y-6">
                <TextField
                  label="Email address"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <TextField
                  label="Password"
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

                <div className="flex items-center justify-between text-sm">
                  <a
                    href="https://www.codeai.studio/forgot-password"
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-400 transition-colors duration-200 hover:text-purple-300"
                  >
                    Forgot Password?
                  </a>
                </div>

                <Button type="submit" disabled={loading || isLoading}>
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="mr-2 h-5 w-5 animate-spin"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                          fill="none"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Signing in...
                    </div>
                  ) : (
                    "Sign In"
                  )}
                </Button>
                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-700" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="bg-gray-900 px-4 text-gray-400">
                        Or continue with
                      </span>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={handleGoogleSignIn}
                    disabled={googleSignInLoading}
                    className="mt-6 group relative flex w-full items-center justify-center gap-3 rounded-lg border border-gray-700 bg-gray-800/50 px-4 py-3 text-white transition-all duration-200 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-purple-500/20"
                  >
                    {googleSignInLoading ? (
                      <span>Loading...</span>
                    ) : (
                      <>
                            <svg viewBox="0 0 24 24" className="h-5 w-5">
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                        <span className="text-sm font-medium">
                          Continue with Google
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </form>

              {error && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className="fixed bottom-4 left-1/2 -translate-x-1/2 transform"
                >
                  <div className="rounded-lg bg-red-500 px-6 py-3 text-white shadow-lg">
                    <p>{error}</p>
                  </div>
                </motion.div>
              )}
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="loading"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="text-white text-center">
              <h2 className="text-3xl font-bold mb-4">Signing In...</h2>
              <p>Please wait while we authenticate you.</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default EmptyState;
