import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch, Provider } from "react-redux";
import { authenticate, logout } from "./components/store/slices/authSlice";
import Header from "./components/Header";
import Chat from "./components/Chat";
import Settings from "./components/Settings";
import Sidebar from "./components/Sidebar";
import EmptyState from "./components/EmptyState";
import { store } from "./components/store/store";
import { ThemeProvider } from "./utils/themeProvider";
import AgentsPage from "./components/Agents";

function AppContent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const dispatch = useDispatch();
  const { user, chatHistory, isLoading } = useSelector((state) => state.auth);

  const toggleUpgradeModal = () => setUpgradeModalVisible(!upgradeModalVisible);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(authenticate(token));
    }
  }, [dispatch]);

  useEffect(() => {
    // Add the Google Analytics script
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-VKXN5VX34Z";
    script.async = true;
    document.head.appendChild(script);

    // Add the inline script to initialize Google Analytics
    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VKXN5VX34Z');
      gtag('event', 'platform', {
        'platform': navigator.platform // Capture the platform information
      });
    `;
    document.head.appendChild(inlineScript);

    // Add Hotjar tracking script
    const hotjarScript = document.createElement("script");
    hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:3632152,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.head.appendChild(hotjarScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
      document.head.removeChild(hotjarScript);
    };
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-slate-900">
        <div className="relative w-16 h-16">
          <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-200 rounded-full"></div>
          <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 rounded-full animate-spin" style={{ borderTopColor: 'transparent' }}></div>
        </div>
      </div>
    );
  }

  if (!user && chatHistory.length === 0) {
    return <EmptyState />;
  }

  return (
    <Router>
      <head>
        <title>CodeAI Web App</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-VKXN5VX34Z" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-VKXN5VX34Z');
            `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3632152,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
      </head>
      <div className="flex h-screen overflow-hidden bg-slate-900">
        <div
          className={sidebarOpen ? "w-40" : "w-0"}
          style={{ transition: "width 300ms ease-in-out" }}
        >
          <Sidebar
            isOpen={sidebarOpen}
            toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
          />
        </div>
        <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
          <Header
            toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
            toggleUpgradeModal={toggleUpgradeModal}
          />
          <main className="flex-1 overflow-hidden">
            <Routes>
              <Route
                path="/"
                element={
                  <Chat
                    upgradeModalVisible={upgradeModalVisible}
                    toggleUpgradeModal={toggleUpgradeModal}
                  />
                }
              />
              <Route path="/settings" element={<Settings />} />
              <Route path="/agents" element={<AgentsPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;