import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
  USER: "user",
  SETTINGS: "settings",
  CHAT_HISTORY: "chatHistory",
  CURRENT_MODEL: "currentModel",
};

export const socketMiddleware = (() => {
  let socket = null;
  let sessionId = uuidv4();

  return (storeAPI) => (next) => (action) => {
    switch (action.type) {
      case "auth/connectSocket": {
        if (socket) socket.disconnect();

        socket = io("https://api.codeai.studio", {
          reconnectionAttempts: 3,
          reconnectionDelay: 1000,
          secure: true,
          transports: ["websocket"],
        });

        socket.on("connect", () => {
          socket.emit("registerSession", { sessionId });
          storeAPI.dispatch({ type: "auth/setConnected", payload: true });
        });

        socket.on("disconnect", () => {
          storeAPI.dispatch({ type: "auth/setConnected", payload: false });
        });

        socket.on("auth-success", (data) => {
          localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token);
          storeAPI.dispatch({ type: "auth/authenticateSuccess", payload: data });
        });

        socket.on("auth-failure", (data) => {
          console.error("Auth failure:", data.message);
          storeAPI.dispatch({ type: "auth/logout" });
        });

        break;
      }

      case "auth/disconnectSocket": {
        if (socket) socket.disconnect();
        socket = null;
        break;
      }

      case "auth/logout": {
        if (socket) {
          socket.emit("deregisterSession", { sessionId });
          socket.disconnect();
        }
        sessionId = uuidv4(); // Reset sessionId for next login
        break;
      }

      default:
        break;
    }

    return next(action);
  };
})();
