import React from 'react';
import { motion } from 'framer-motion';
import { Crown, Sparkles } from "lucide-react";

export const MonthlyLimitReached = ({ theme, toggleUpgradeModal }) => (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="p-8 rounded-2xl"
      style={{
        background: `linear-gradient(135deg, ${theme.colors.surface}, ${theme.colors.surface}90)`,
        border: `1px solid ${theme.colors.primary}20`,
        boxShadow: `0 8px 32px -4px ${theme.colors.primary}20`
      }}
    >
      <div className="flex flex-col items-center text-center gap-6">
        <motion.div
          animate={{ 
            scale: [1, 1.1, 1],
            rotate: [0, 5, -5, 0]
          }}
          transition={{ duration: 3, repeat: Infinity }}
          className="p-4 rounded-full"
          style={{ backgroundColor: `${theme.colors.primary}20` }}
        >
          <Crown className="h-8 w-8" style={{ color: theme.colors.primary }} />
        </motion.div>
        <div>
          <h3 className="text-xl font-bold mb-2" style={{ color: theme.colors.primary }}>
            Monthly Limit Reached
          </h3>
          <p className="text-base mb-6" style={{ color: `${theme.colors.text}90` }}>
            Upgrade to Pro for unlimited access to CodeAI's advanced features
          </p>
        </div>
        <motion.button
          whileHover={{ 
            scale: 1.02,
            boxShadow: `0 8px 32px -4px ${theme.colors.primary}40`
          }}
          whileTap={{ scale: 0.98 }}
          onClick={toggleUpgradeModal}
          className="flex items-center gap-3 px-6 py-3 rounded-xl"
          style={{
            background: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.secondary})`,
            boxShadow: `0 4px 16px ${theme.colors.primary}30`,
          }}
        >
          <Sparkles className="w-5 h-5 text-white" />
          <span className="text-base font-medium text-white">Upgrade to Pro</span>
        </motion.button>
      </div>
    </motion.div>
  );