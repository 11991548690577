// utils/axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.codeai.studio/api',
});

export const setupInterceptors = (store) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch({ type: 'auth/logout' });
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;