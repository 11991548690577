export const models = [
  {
    name: "GPT-3.5",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png",
    description: "Fast & reliable for everyday tasks",
    category: "OpenAI"
  },
  {
    name: "GPT-4",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png",
    description: "Advanced reasoning & analysis",
    category: "OpenAI"
  },
  {
    name: "GPT-4O",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png",
    description: "Enhanced with online search capabilities",
    category: "OpenAI"
  },
  {
    name: "GPT-4O-mini",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png",
    description: "Streamlined online search model",
    category: "OpenAI"
  },
  {
    name: "Claude-3-5-sonnet",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png",
    description: "Latest Sonnet model with enhanced capabilities",
    category: "Anthropic"
  },
  {
    name: "Claude-3-opus",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png",
    description: "Most capable for complex tasks",
    category: "Anthropic"
  },
  {
    name: "Claude-3-sonnet",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png",
    description: "Balanced performance & speed",
    category: "Anthropic"
  },
  {
    name: "Claude-3-haiku",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png",
    description: "Fast responses for quick tasks",
    category: "Anthropic"
  }
];