import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { getSettings, getUser, setModel } from "./api";
import { debounce } from "lodash";

const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
  USER: "user",
  SETTINGS: "settings",
  CHAT_HISTORY: "chatHistory",
  CURRENT_MODEL: "currentModel",
};

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const createSocket = (config) => {
  const socket = io("https://api.codeai.studio", {
    reconnectionAttempts: 3,
    reconnectionDelay: 1000,
    secure: true,
    transports: ["websocket"],
  });

  Object.entries(config).forEach(([event, handler]) => {
    socket.on(event, handler);
  });

  return socket;
};

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(() => ({
    token: localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN),
    user: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)),
    settings: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS)),
    isConnected: false,
    isLoading: false,
    currentModel:
      localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_MODEL) || "gpt-3.5",
    hasSubscription: true,
    chatHistory:
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CHAT_HISTORY)) || [],
    requestsRemaining:
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS))
        ?.requestsRemaining || 0,
  }));

  const sessionId = useRef(uuidv4());
  const socketRef = useRef(null);
  const isConnectedRef = useRef(false);
  const attemptCountRef = useRef(0);
  const MAX_ATTEMPTS = 3;

  const updateState = useCallback((newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const saveChatHistory = useCallback(
    debounce((messages) => {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.CHAT_HISTORY,
        JSON.stringify(messages)
      );
    }, 1000),
    []
  );

  const navigateToHome = useCallback(() => {
    window.location.hash = "#/";
  }, []);

  

  const handleLogout = useCallback(() => {
    Object.values(LOCAL_STORAGE_KEYS).forEach((key) =>
      localStorage.removeItem(key)
    );
    updateState({
      token: null,
      user: null,
      settings: null,
      currentModel: "",
      hasSubscription: true,
      chatHistory: [],
      requestsRemaining: 0,
    });
    sessionId.current = uuidv4();
    if (socketRef.current) {
      socketRef.current.emit("deregisterSession", {
        sessionId: sessionId.current,
      });
      socketRef.current.disconnect();
    }
    navigateToHome();
  }, [navigateToHome, updateState]);

  const switchModel = useCallback(
    async (model) => {
      const newModel = model || "gpt-3.5"; // Default to "gpt-3.5" if no model is provided
      updateState({ currentModel: newModel });
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_MODEL, newModel);
      const token = state.token;
      if (token) {
        try {
          await setModel(token, newModel);
        } catch (error) {
          console.error("Error switching model:", error);
        }
      } else {
        console.warn("No token available, skipping model switch on server");
      }
    },
    [state.token, updateState]
  );

  const addMessageToHistory = useCallback(
    (message) => {
      setState((prevState) => {
        const updatedHistory =
          prevState.chatHistory.findIndex((msg) => msg.id === message.id) !== -1
            ? prevState.chatHistory.map((msg) =>
                msg.id === message.id
                  ? { ...msg, text: msg.text + message.text }
                  : msg
              )
            : [...prevState.chatHistory, message];

        saveChatHistory(updatedHistory);
        return { ...prevState, chatHistory: updatedHistory };
      });
    },
    [saveChatHistory]
  );



  const getSettingsWithRetry = useCallback(async (token, attempts = 0) => {
    if (attempts >= MAX_ATTEMPTS) {
      console.warn(
        `Failed to fetch settings after ${MAX_ATTEMPTS} attempts. Using default settings.`
      );
      return { selectedModel: "gpt-3.5", requestsRemaining: 0, plan: "Free" };
    }

    try {
      return await getSettings(token);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn("Settings not found. Using default settings.");
        return { selectedModel: "gpt-3.5", requestsRemaining: 0, plan: "Free" };
      }
      console.error(
        `Error fetching settings (attempt ${attempts + 1}):`,
        error
      );
      return getSettingsWithRetry(token, attempts + 1);
    }
  }, []);

  const authenticate = useCallback(async (token) => {
    updateState({ isLoading: true });
    try {
      const [userData, settingsData] = await Promise.all([
        getUser(token),
        getSettings(token)
      ]);
  
      const newState = {
        token,
        user: userData.user,
        settings: settingsData,
        currentModel: settingsData.selectedModel || "gpt-3.5",
        requestsRemaining: settingsData.requestsRemaining,
        isLoading: false,
      };
  
      updateState(newState);
      // Update localStorage
      Object.entries(newState).forEach(([key, value]) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS[key.toUpperCase()], JSON.stringify(value));
      });
  
      switchModel(settingsData.selectedModel);
  
      if (socketRef.current && isConnectedRef.current) {
        socketRef.current.emit("authenticate", {
          token,
          sessionId: sessionId.current,
        });
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      handleLogout();
    } finally {
      updateState({ isLoading: false });
    }
  }, [updateState, switchModel, handleLogout]);



  const initializeSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    socketRef.current = createSocket({
      connect: () => {
        isConnectedRef.current = true;
        socketRef.current.emit("registerSession", {
          sessionId: sessionId.current,
        });
      },
      disconnect: () => {
        isConnectedRef.current = false;
      },
      "auth-success": (data) => {
        updateState({ token: data.token });
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token);
        authenticate(data.token);
      },
      "auth-failure": (data) => {
        console.error("Auth failure:", data.message);
        handleLogout();
      },
    });
  }, [authenticate, updateState, handleLogout]);

  const updateSettings = useCallback(async () => {
    if (attemptCountRef.current >= MAX_ATTEMPTS) {
      console.warn(
        `Failed to update settings after ${MAX_ATTEMPTS} attempts. Stopping further attempts.`
      );
      return;
    }
    const token = state.token;
    attemptCountRef.current += 1;

    try {
      const settingsData = await getSettings(token);

      if (settingsData.error === "NO_ACTIVE_SUBSCRIPTION") {
        updateState({
          settings: { error: "NO_ACTIVE_SUBSCRIPTION" },
          requestsRemaining: 0,
        });
      } else {
        updateState({
          settings: settingsData,
          requestsRemaining: settingsData.requestsRemaining,
        });
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.SETTINGS,
          JSON.stringify(settingsData)
        );
      }

      attemptCountRef.current = 0; // Reset counter on success
    } catch (error) {
      console.error("Error fetching settings:", error);
      // No retry logic, just log the error
    }
  }, [updateState, state.token]);

  const handleGoogleSignIn = useCallback(() => {
    initializeSocket();
    const url = `https://api.codeai.studio/api/auth/google?sessionId=${sessionId.current}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }, [initializeSocket]);

  const decrementRequestsRemaining = useCallback(() => {
    updateState((prevState) => {
      const newRequestsRemaining = Math.max(0, prevState.requestsRemaining - 1);
      const updatedSettings = {
        ...prevState.settings,
        requestsRemaining: newRequestsRemaining,
      };
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SETTINGS,
        JSON.stringify(updatedSettings)
      );
      return {
        settings: updatedSettings,
        requestsRemaining: newRequestsRemaining,
      };
    });
  }, [updateState]);



  const initializeAndAuthenticate = useCallback(async () => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (token) {
      updateState({ token, isLoading: true });
      try {
        const [userData, settingsData] = await Promise.all([
          getUser(token),
          getSettings(token)
        ]);
        updateState({
          user: userData.user,
          settings: settingsData,
          currentModel: settingsData.selectedModel || "gpt-3.5",
          requestsRemaining: settingsData.requestsRemaining,
          isLoading: false
        });
        switchModel(settingsData.selectedModel);
      } catch (error) {
        console.error("Authentication failed:", error);
        handleLogout();
      }
    }
    initializeSocket();
  }, [updateState, switchModel, handleLogout, initializeSocket]);

  const contextValue = useMemo(
    () => ({
      ...state,
      authenticate,
      sessionId: sessionId.current,
      addMessageToHistory,
      clearHistory: () => {
        updateState({ chatHistory: [] });
        saveChatHistory([]);
      },
      logout: handleLogout,
      switchModel,
      updateSettings,
      decrementRequestsRemaining,
      setToken: (token) => updateState({ token }),
      setUser: (user) => updateState({ user }),
      setSettings: (settings) => updateState({ settings }),
      initializeAndAuthenticate,
      handleGoogleSignIn
    }),
    [
      state,
      authenticate,
      addMessageToHistory,
      handleLogout,
      switchModel,
      updateSettings,
      decrementRequestsRemaining,
      saveChatHistory,
      updateState,
      initializeAndAuthenticate,
      handleGoogleSignIn
    ]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
