import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import { ClipboardIcon, CheckIcon } from "@heroicons/react/outline";
import { isVSCode } from "../utils/environment";
import { useTheme } from "../utils/themeProvider";
import { motion, AnimatePresence } from "framer-motion";

// Separate CopyMessageButton into its own file for better code splitting
const CopyMessageButton = React.memo(({ onCopy, copied }) => {
  const { theme } = useTheme();

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onCopy}
      className="text-xs rounded-md px-3 py-1.5 transition-all duration-300 flex items-center gap-2"
      style={{
        backgroundColor: copied ? theme.colors.primary : 'transparent',
        color: copied ? theme.colors.background : theme.colors.text,
      }}
      title="Copy Message"
    >
      {copied ? (
        <>
          <CheckIcon className="h-4 w-4" />
          <span className="font-medium">Copied</span>
        </>
      ) : (
        <>
          <ClipboardIcon className="h-4 w-4" />
          <span className="font-medium">Copy</span>
        </>
      )}
    </motion.button>
  );
});

// Create a separate LoadingIndicator component
const LoadingIndicator = React.memo(({ theme }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    className="flex items-start mt-6 pl-14"
  >
    <div className="flex flex-col">
      <div className="flex items-center space-x-3">
        <span
          className="text-sm font-medium"
          style={{ color: theme.colors.text }}
        >
          CodeAI is thinking
        </span>
        <div className="flex space-x-1">
          {[0, 0.2, 0.4].map((delay, i) => (
            <motion.div
              key={i}
              initial={{ scale: 0.8 }}
              animate={{ scale: [0.8, 1.2, 0.8] }}
              transition={{
                repeat: Infinity,
                duration: 1.5,
                delay: delay,
                ease: "easeInOut",
              }}
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: theme.colors.primary }}
            />
          ))}
        </div>
      </div>
    </div>
  </motion.div>
));

const ChatMessage = React.memo(
  ({ msg, user, isLoading, highlightWhenComplete = false }) => {
    const { theme } = useTheme();
    const [copiedCodeIds, setCopiedCodeIds] = useState({});
    const [insertedCodeIds, setInsertedCodeIds] = useState({});
    const [copiedMessage, setCopiedMessage] = useState(false);
    const codeBlocksRef = useRef(new Map());
    const messageRef = useRef(null);
    const isUser = msg.type === "user" || msg.type === "user-code";

    const debouncedCopy = useCallback(async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        return true;
      } catch (error) {
        console.error("Failed to copy text: ", error);
        return false;
      }
    }, []);

    const copyToClipboard = useCallback(
      async (text) => {
        const success = await debouncedCopy(text);
        return success;
      },
      [debouncedCopy]
    );

    const handleCopyCodeClick = useCallback(
      async (code, codeId) => {
        const success = await copyToClipboard(code);
        if (success) {
          setCopiedCodeIds((prev) => ({ ...prev, [codeId]: true }));
          setTimeout(() => {
            setCopiedCodeIds((prev) => ({ ...prev, [codeId]: false }));
          }, 3000);
        }
      },
      [copyToClipboard]
    );

    const handleInsertClick = useCallback((code, codeId) => {
      if (isVSCode()) {
        const vscode = window.acquireVsCodeApi();
        vscode.postMessage({ command: "insert", text: code });
        setInsertedCodeIds((prev) => ({ ...prev, [codeId]: true }));
        setTimeout(() => {
          setInsertedCodeIds((prev) => ({ ...prev, [codeId]: false }));
        }, 2000);
      }
    }, []);

    const handleCopyMessageClick = useCallback(async () => {
      const success = await copyToClipboard(msg.text);
      if (success) {
        setCopiedMessage(true);
        setTimeout(() => setCopiedMessage(false), 2000);
      }
    }, [copyToClipboard, msg.text]);

    const customRenderer = useMemo(() => {
      const renderer = new marked.Renderer();
      const langCounters = {};

      renderer.code = (code, language) => {
        const lang = code.lang || "plaintext";
        langCounters[lang] = (langCounters[lang] || 0) + 1;
        const codeId = `message-${msg.id}-code-${lang}-${langCounters[lang]}`;
        codeBlocksRef.current.set(codeId, code.text);
        const highlightedCode = hljs.highlight(code.text, {
          language: lang,
          ignoreIllegals: true,
        }).value;

        return `
        <div class="relative my-6 rounded-xl overflow-hidden" 
             style="border: 1px solid ${theme.colors.primary}40;" data-code-id="${codeId}">
          <div class="flex justify-between items-center p-4" 
               style="background: linear-gradient(135deg, ${theme.colors.surface}80, ${theme.colors.hover}90);">
            <div class="flex items-center gap-2">
              <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="${theme.colors.primary}">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
              </svg>
              <span class="text-sm font-mono font-medium" style="color: ${theme.colors.primary};">${lang}</span>
            </div>
            <div class="flex gap-3">
              <button
                data-action="copy"
                data-code-id="${codeId}"
                class="copy-button text-xs rounded-lg px-4 py-2 transition-all duration-300 flex items-center gap-2 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-opacity-50"
                style="background-color: ${theme.colors.secondary}; color: ${theme.colors.text};"
                title="Copy Code"
              >
                <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                <span class="font-medium">${copiedCodeIds[codeId] ? "Copied!" : "Copy"}</span>
              </button>
              ${
                isVSCode()
                  ? `
                <button
                  data-action="insert"
                  data-code-id="${codeId}"
                  class="insert-button text-xs rounded-lg px-4 py-2 transition-all duration-300 flex items-center gap-2 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-opacity-50"
                  style="background-color: ${theme.colors.primary}; color: ${theme.colors.text};"
                  title="Insert Code"
                >
                  <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  <span class="font-medium">${insertedCodeIds[codeId] ? "Inserted!" : "Insert"}</span>
                </button>
              `
                  : ""
              }
            </div>
          </div>
          <div style="background-color: ${theme.colors.background};" 
               class="p-6 overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-track-transparent">
            <pre class="m-0"><code class="hljs ${lang} text-sm leading-relaxed">${highlightedCode}</code></pre>
          </div>
        </div>
      `;
      };

      renderer.heading = (text, level) => {
        const sizes = {
          1: "text-3xl",
          2: "text-2xl",
          3: "text-xl",
          4: "text-lg",
          5: "text-base",
          6: "text-sm",
        };
        return `<h${level} class="${sizes[level]} font-bold mt-8 mb-4" style="color: ${theme.colors.primary};">${text.text}</h${level}>`;
      };

      renderer.paragraph = (text) => {
        return `<p class="mt-4 mb-4 leading-7" style="color: ${theme.colors.text};">${text.text}</p>`;
      };

      renderer.list = (body, ordered) => {
        const tag = ordered ? "ol" : "ul";
        const listItems = body.items
          .map(
            (item) => `
          <li class="mb-2 pl-2" style="color: ${theme.colors.text};">
            ${marked.parser(item.tokens)}
          </li>
        `
          )
          .join("");
        return `
        <${tag} class="${ordered ? "list-decimal" : "list-disc"} mt-3 mb-3 ml-6 space-y-1">
          ${listItems}
        </${tag}>
      `;
      };

      renderer.blockquote = (quote) => {
        return `<blockquote class="border-l-4 pl-4 py-2 my-4 italic" style="border-color: ${theme.colors.primary}; background-color: ${theme.colors.hover}; color: ${theme.colors.text};">${quote}</blockquote>`;
      };

      return renderer;
    }, [msg.id, copiedCodeIds, insertedCodeIds, theme]);

    const renderedContent = useMemo(() => {
      return marked(msg.text, { renderer: customRenderer });
    }, [msg.text, customRenderer]);

    useEffect(() => {
      const handleButtonClick = (event) => {
        const button = event.target.closest("button[data-action]");
        if (!button) return;
        const action = button.getAttribute("data-action");
        const codeId = button.getAttribute("data-code-id");

        if (!action || !codeId) return;

        const code = codeBlocksRef.current.get(codeId);
        if (!code) return;

        if (action === "copy") {
          handleCopyCodeClick(code, codeId);
        } else if (action === "insert") {
          handleInsertClick(code, codeId);
        }
      };

      const messageElement = messageRef.current;
      if (messageElement) {
        messageElement.addEventListener("click", handleButtonClick);
        return () => messageElement.removeEventListener("click", handleButtonClick);
      }
    }, [handleCopyCodeClick, handleInsertClick]);

    return (
      <div className="w-full max-w-4xl mx-auto mb-8">
        <motion.div
          ref={messageRef}
          className="group relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* Author identifier */}
          <div className="flex items-center gap-3 mb-3">
            <div 
              className="w-10 h-10 rounded-full flex items-center justify-center shadow-lg"
              style={{
                background: isUser
                  ? `linear-gradient(135deg, ${theme.colors.userGradientStart}, ${theme.colors.userGradientEnd})`
                  : `linear-gradient(135deg, ${theme.colors.aiGradientStart}, ${theme.colors.aiGradientEnd})`
              }}
            >
              <span className="text-white text-sm font-bold">
                {isUser ? user?.first_name?.charAt(0) || "U" : "AI"}
              </span>
            </div>
            <span 
              className="text-sm font-medium"
              style={{ color: theme.colors.text }}
            >
              {isUser ? "You" : "CodeAI"}
            </span>
          </div>

          {/* Message container with border and background */}
          <div 
            className="relative pl-12"

          >
            {/* Message content with distinctive container */}
            <div 
              className="p-6 rounded-lg backdrop-blur-lg"
              style={{
                backgroundColor: isUser ? theme.colors.userMessage : theme.colors.aiMessage,
                border: `1px solid ${isUser ? theme.colors.userMessageBorder : theme.colors.aiMessageBorder}`,
                boxShadow: `0 4px 6px ${theme.colors.messageShadow}`
              }}
            >
              <div 
                className="prose max-w-none"
                dangerouslySetInnerHTML={{ __html: renderedContent }}
              />
              
              {/* Copy message button */}
              <div className="mt-4 flex justify-end opacity-0 group-hover:opacity-100 transition-opacity">
                <CopyMessageButton
                  onCopy={handleCopyMessageClick}
                  copied={copiedMessage}
                />
              </div>
            </div>
          </div>
        </motion.div>

        <AnimatePresence>
          {isLoading && <LoadingIndicator theme={theme} />}
        </AnimatePresence>
      </div>
    );
  }
);

export default ChatMessage;