import React from 'react';
import { useTheme } from "../utils/themeProvider";
import { themes } from "../utils/themes";
import { motion } from 'framer-motion';

const ThemeSelector = () => {
    const { theme, changeTheme } = useTheme();
  
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {Object.entries(themes).map(([key, themeOption]) => (
          <motion.div
            key={key}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => changeTheme(key)}
            className={`cursor-pointer rounded-lg p-4 border-2 transition-all duration-300 ${
              theme === themeOption ? 'border-blue-500' : 'border-transparent'
            }`}
            style={{ backgroundColor: themeOption.colors.background }}
          >
            <div className="flex flex-col space-y-2">
              <div className="w-full h-20 rounded" style={{ backgroundColor: themeOption.colors.primary }} />
              <div className="w-full h-4 rounded" style={{ backgroundColor: themeOption.colors.secondary }} />
              <div className="text-xs font-medium text-center" style={{ color: themeOption.colors.text }}>
                {themeOption.name}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    );
  };

export default ThemeSelector;