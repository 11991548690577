import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  Plus,
  Search,
  Star,
  Edit2,
  Trash2,
  Loader,
  ChevronLeft,
  ChevronRight,
  Info,
  ChevronUp,
  User,
  Code,
  Globe,
  Lock,
  MessageCircle,
  ArrowRight,
} from "lucide-react";
import {
  fetchAgents,
  createAgent,
  updateAgent,
  deleteAgent,
  toggleAgentStar,
} from "./store/slices/agentsSlice";
import { useTheme } from "../utils/themeProvider";
import { selectToken, switchModel } from "./store/slices/authSlice";
import { useNavigate } from "react-router-dom";

const ITEMS_PER_PAGE = 10;

const AGENT_LIMITS = {
  free_monthly: 1,
  free_annual: 1,
  explorer_monthly: 1,
  explorer_annual: 1,
  innovator_monthly: 3,
  innovator_annual: 3,
  solo_monthly: 3,
  solo_annual: 3,
  master_monthly: 5,
  master_annual: 5,
  pro_monthly: 5,
  pro_annual: 5,
  guru_monthly: 10,
  guru_annual: 10,
  enterprise_monthly: 999,
  enterprise_annual: 999,
};

const AgentsUsageIndicator = ({ currentCount, limit }) => {
  const { theme } = useTheme();
  const percentage = (currentCount / limit) * 100;
  const isAtLimit = currentCount >= limit;

  return (
    <div
      className="mb-6 p-6 rounded-xl"
      style={{ backgroundColor: theme.colors.surface }}
    >
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <span
              className="text-lg font-semibold"
              style={{ color: theme.colors.text }}
            >
              AI Agents Usage
            </span>
            <Info
              className="w-4 h-4 opacity-50"
              style={{ color: theme.colors.text }}
            />
          </div>
          <div className="flex items-end gap-2">
            <span
              className="text-3xl font-bold"
              style={{
                color: isAtLimit ? theme.colors.danger : theme.colors.text,
              }}
            >
              {currentCount}
            </span>
            <span
              className="text-sm opacity-70 mb-1"
              style={{ color: theme.colors.text }}
            >
              of {limit} agents used
            </span>
          </div>
        </div>

        {isAtLimit && (
          <Button variant="primary" className="flex items-center gap-2">
            Upgrade Now <ArrowRight className="w-4 h-4" />
          </Button>
        )}
      </div>

      <div className="mt-4">
        <div
          className="h-3 w-full rounded-full overflow-hidden bg-opacity-20"
          style={{ backgroundColor: `${theme.colors.primary}20` }}
        >
          <div
            className="h-full rounded-full transition-all duration-300"
            style={{
              width: `${percentage}%`,
              background: isAtLimit
                ? `linear-gradient(90deg, ${theme.colors.danger}, ${theme.colors.danger})`
                : `linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.accent})`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Button = ({
  children,
  onClick,
  variant = "default",
  disabled,
  className = "",
}) => {
  const { theme } = useTheme();
  const baseStyle =
    "px-4 py-2 rounded-lg font-medium transition-all duration-200 flex items-center gap-2";
  const variants = {
    primary: `bg-opacity-90 hover:bg-opacity-100 text-white ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`,
    default: `bg-opacity-80 hover:bg-opacity-100 ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`,
    danger: "bg-red-500 bg-opacity-80 hover:bg-opacity-100 text-white",
  };

  const getBackgroundColor = () => {
    if (variant === "primary") return theme.colors.primary;
    if (variant === "default") return theme.colors.surface;
    return "";
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyle} ${variants[variant]} ${className}`}
      style={{
        backgroundColor: getBackgroundColor(),
        color: variant === "primary" ? theme.colors.ctaText : theme.colors.text,
      }}
    >
      {children}
    </button>
  );
};

const Input = ({ icon: Icon, ...props }) => {
  const { theme } = useTheme();
  return (
    <div className="relative">
      {Icon && (
        <div
          className="absolute left-3 top-1/2 transform -translate-y-1/2"
          style={{ color: theme.colors.text }}
        >
          <Icon className="w-5 h-5 opacity-50" />
        </div>
      )}
      <input
        {...props}
        className="w-full px-4 py-2 rounded-lg border transition-all duration-200 pl-10"
        style={{
          backgroundColor: theme.colors.surface,
          borderColor: theme.colors.userMessageBorder,
          color: theme.colors.text,
        }}
      />
    </div>
  );
};

const AgentCard = ({
  agent,
  onEdit,
  onDelete,
  onToggleStar,
  onChat,
  currentUser,
}) => {
  const { theme } = useTheme();

  const starColor = agent.isStarred ? theme.colors.accent : theme.colors.text;
  const starFill = agent.isStarred ? theme.colors.accent : "none";

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="rounded-xl p-6 relative backdrop-blur-sm transition-all duration-200 hover:shadow-lg"
      style={{
        backgroundColor: theme.colors.surface,
        borderColor: theme.colors.userMessageBorder,
        boxShadow: `0 4px 6px ${theme.colors.messageShadow}`,
      }}
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <div
              className="w-10 h-10 rounded-full flex items-center justify-center text-white text-lg font-bold mr-3"
              style={{
                background: `linear-gradient(135deg, ${theme.colors.userGradientStart}, ${theme.colors.userGradientEnd})`,
              }}
            >
              {agent.name[0].toUpperCase()}
            </div>
            <div>
              <h3
                className="text-lg font-semibold truncate"
                style={{ color: theme.colors.text }}
              >
                {agent.name}
              </h3>
              <p
                className="text-sm opacity-70 flex items-center"
                style={{ color: theme.colors.text }}
              >
                <User size={14} className="mr-1" /> @{agent.createdBy}
              </p>
            </div>
          </div>
          <button
            onClick={() => onToggleStar(agent.assistantId)}
            className="flex items-center transition-all duration-200"
            style={{ color: starColor }}
          >
            <Star className="w-5 h-5 mr-1" fill={starFill} />
            <span className="text-sm font-medium">{agent.starCount ?? 0}</span>
          </button>
        </div>

        <div className="mt-auto flex items-center justify-between">
          <div className="flex items-center">
            <span
              className="px-2 py-1 rounded-full text-xs font-medium mr-2 flex items-center"
              style={{
                backgroundColor: agent.isPublic
                  ? theme.colors.primary
                  : theme.colors.surface,
                color: agent.isPublic
                  ? theme.colors.ctaText
                  : theme.colors.text,
                opacity: 0.8,
              }}
            >
              {agent.isPublic ? (
                <Globe size={12} className="mr-1" />
              ) : (
                <Lock size={12} className="mr-1" />
              )}
              {agent.isPublic ? "Public" : "Private"}
            </span>
            <span
              className="text-xs opacity-70 flex items-center"
              style={{ color: theme.colors.text }}
            >
              <Code size={12} className="mr-1" /> {agent.model}
            </span>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => onChat(agent)}
              className="p-2 rounded-full transition-all duration-200 hover:bg-opacity-10 flex items-center"
              style={{
                color: theme.colors.text,
                backgroundColor: `${theme.colors.primary}`,
              }}
            >
              <MessageCircle size={16} className="mr-1" />
              <span className="text-xs">Use AI Agent</span>
            </button>
          </div>
          {agent.createdBy === currentUser?.username && (
            <div className="flex space-x-2">
              <button
                onClick={() => onEdit(agent)}
                className="p-1 rounded-full transition-all duration-200 hover:bg-opacity-10"
                style={{
                  color: theme.colors.primary,
                  backgroundColor: `${theme.colors.primary}10`,
                }}
              >
                <Edit2 size={16} />
              </button>
              <button
                onClick={() => onDelete(agent.assistantId)}
                className="p-1 rounded-full transition-all duration-200 hover:bg-opacity-10"
                style={{
                  color: theme.colors.danger,
                  backgroundColor: `${theme.colors.danger}10`,
                }}
              >
                <Trash2 size={16} />
              </button>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { theme } = useTheme();
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex items-center justify-center space-x-2 mt-8">
      <Button
        variant="default"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeft className="w-4 h-4" />
      </Button>

      {pages.map((page) => (
        <Button
          key={page}
          variant={currentPage === page ? "primary" : "default"}
          onClick={() => onPageChange(page)}
          className="w-10 h-10 p-0 flex items-center justify-center"
        >
          {page}
        </Button>
      ))}

      <Button
        variant="default"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRight className="w-4 h-4" />
      </Button>
    </div>
  );
};

const AgentsPage = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingAgent, setEditingAgent] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const { user } = useSelector((state) => state.auth);
  const {
    isLoading,
    allAgents,
    myAgents,
    publicAgents,
    favoriteAgents,
    error,
  } = useSelector((state) => state.agents);

  const navigate = useNavigate();

  const currentCount = myAgents?.items?.length || 0;
  const limit = AGENT_LIMITS[user?.plan || "free_monthly"];
  console.log(user.plan);
  const isLimitReached = currentCount >= limit;

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const fetchAgentsData = useCallback(() => {
    dispatch(
      fetchAgents({
        type: activeTab,
        token,
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        searchTerm,
      })
    );
  }, [dispatch, activeTab, token, currentPage, searchTerm]);

  const handleChat = async (agent) => {
    try {
      const modelConfig = {
        name: agent.model,
        id: `assistants-${agent.assistantId}`,
        type: "assistant",
        instructions: agent.instructions,
      };
      await dispatch(
        switchModel({
          token,
          model: modelConfig,
        })
      ).unwrap();
      navigate("/chat");
    } catch (error) {
      console.error("Failed to start chat with agent:", error);
    }
  };

  useEffect(() => {
    fetchAgentsData();
  }, [fetchAgentsData, activeTab, currentPage]);

  const getCurrentAgents = () => {
    switch (activeTab) {
      case "all":
        return allAgents;
      case "my":
        return myAgents;
      case "public":
        return publicAgents;
      case "favorites":
        return favoriteAgents;
      default:
        return allAgents;
    }
  };
  const handleCreateOrUpdate = async (formData) => {
    try {
      if (editingAgent) {
        await dispatch(
          updateAgent({
            formData,
            assistantId: editingAgent.assistantId,
            token,
          })
        ).unwrap();
      } else {
        if (currentCount >= limit) {
          return;
        }

        await dispatch(createAgent({ formData, token })).unwrap();
      }
      setIsModalOpen(false);
      setEditingAgent(null);
      fetchAgentsData();
    } catch (error) {}
  };

  const handleDelete = async (agentId) => {
    if (window.confirm("Are you sure you want to delete this agent?")) {
      try {
        await dispatch(deleteAgent({ agentId, token })).unwrap();
        fetchAgentsData();
      } catch (error) {
        console.error("Failed to delete agent:", error);
      }
    }
  };

  const handleSearch = useCallback((value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  }, []);

  return (
    <div
      className="min-h-screen py-8 px-4 sm:px-6 lg:px-8 overflow-y-auto h-full"
      style={{
        backgroundColor: theme.colors.background,
        overflowY: "auto", // Enable vertical scrolling
      }}
    >
      <div className="max-w-7xl mx-auto pb-24"> {/* Added larger bottom padding */}
        <div className="flex justify-between items-center mb-8">
          <h1
            className="text-3xl font-bold"
            style={{ color: theme.colors.text }}
          >
            AI Agents
          </h1>
          <Button
            variant="primary"
            onClick={() => setIsModalOpen(true)}
            disabled={isLimitReached}
            className={isLimitReached ? "opacity-50 cursor-not-allowed" : ""}
          >
            <Plus className="w-5 h-5" />
            Create Agent
          </Button>
        </div>

        <AgentsUsageIndicator currentCount={currentCount} limit={limit} />

        <div className="space-y-4 mb-8">
          <Input
            icon={Search}
            type="text"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search agents..."
          />

          <div className="flex space-x-2 overflow-x-auto pb-2">
            {["all", "my", "public", "favorites"].map((tab) => (
              <Button
                key={tab}
                variant={activeTab === tab ? "primary" : "default"}
                onClick={() => {
                  setActiveTab(tab);
                  setCurrentPage(1);
                }}
                className="capitalize"
              >
                {tab}
              </Button>
            ))}
          </div>
        </div>

        {isLoading && currentPage === 1 ? (
          <div className="flex justify-center items-center py-12">
            <Loader
              className="w-8 h-8 animate-spin"
              style={{ color: theme.colors.primary }}
            />
          </div>
        ) : (
          <>
            <motion.div
              layout
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              <AnimatePresence>
                {getCurrentAgents()?.items?.map((agent) => (
                  <AgentCard
                    key={`${agent.assistantId}-${agent.isStarred}`}
                    agent={agent}
                    currentUser={user}
                    onEdit={(agent) => {
                      setEditingAgent(agent);
                      setIsModalOpen(true);
                    }}
                    onDelete={handleDelete}
                    onChat={handleChat}
                    onToggleStar={(agentId) =>
                      dispatch(
                        toggleAgentStar({
                          agentId,
                          token,
                          currentTab: activeTab,
                        })
                      )
                        .unwrap()
                        .then(() => {
                          if (activeTab === "favorites") {
                            fetchAgentsData();
                          }
                        })
                        .catch((error) => {
                          console.error("Failed to toggle star:", error);
                        })
                    }
                  />
                ))}
              </AnimatePresence>
            </motion.div>

            {getCurrentAgents()?.items?.length === 0 && !isLoading && (
              <div className="flex flex-col items-center justify-center py-16 text-center">
                <div 
                  className="w-16 h-16 mb-4 rounded-full flex items-center justify-center"
                  style={{ backgroundColor: `${theme.colors.primary}20` }}
                >
                  <MessageCircle 
                    size={32} 
                    style={{ color: theme.colors.primary }} 
                  />
                </div>
                <h3 
                  className="text-xl font-semibold mb-2"
                  style={{ color: theme.colors.text }}
                >
                  No agents found
                </h3>
                <p 
                  className="text-md opacity-70 mb-6 max-w-md"
                  style={{ color: theme.colors.text }}
                >
                  {activeTab === "my" 
                    ? "You haven't created any agents yet. Create your first agent to get started!"
                    : activeTab === "favorites" 
                      ? "You haven't starred any agents yet. Explore and star agents to find them here!"
                      : "No agents match your current search. Try changing your search terms or filters."}
                </p>
                {activeTab === "my" && !isLimitReached && (
                  <Button 
                    variant="primary" 
                    onClick={() => setIsModalOpen(true)}
                  >
                    <Plus className="w-5 h-5 mr-1" />
                    Create Your First Agent
                  </Button>
                )}
              </div>
            )}

            {getCurrentAgents()?.totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={getCurrentAgents().totalPages}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  scrollToTop();
                }}
              />
            )}
          </>
        )}

        {isLoading && currentPage > 1 && (
          <div className="flex justify-center items-center py-8">
            <Loader
              className="w-6 h-6 animate-spin"
              style={{ color: theme.colors.primary }}
            />
          </div>
        )}

        {showScrollTop && (
          <Button
            variant="primary"
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 rounded-full w-12 h-12 p-0 flex items-center justify-center shadow-lg"
          >
            <ChevronUp className="w-6 h-6" />
          </Button>
        )}
      </div>

      {isModalOpen && (
        <AgentFormModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEditingAgent(null);
          }}
          onSubmit={handleCreateOrUpdate}
          initialData={editingAgent}
        />
      )}
    </div>
  );
};

const AgentFormModal = ({ isOpen, onClose, onSubmit, initialData }) => {
  const { theme } = useTheme();
  const [formData, setFormData] = useState({
    name: initialData?.name || "",
    instructions: initialData?.instructions || "",
    model: initialData?.model || "gpt-4",
    isPublic: initialData?.isPublic || false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="w-full max-w-lg rounded-xl p-6 max-h-[90vh] overflow-y-auto"
        style={{ backgroundColor: theme.colors.surface }}
      >
        <h2
          className="text-2xl font-bold mb-6"
          style={{ color: theme.colors.text }}
        >
          {initialData ? "Edit Agent" : "Create New Agent"}
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              className="block text-sm font-medium mb-2"
              style={{ color: theme.colors.text }}
            >
              Name
            </label>
            <Input
              type="text"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
          </div>

          <div>
            <label
              className="block text-sm font-medium mb-2"
              style={{ color: theme.colors.text }}
            >
              Instructions
            </label>
            <textarea
              value={formData.instructions}
              onChange={(e) =>
                setFormData({ ...formData, instructions: e.target.value })
              }
              required
              className="w-full px-4 py-2 rounded-lg border transition-all duration-200"
              style={{
                backgroundColor: theme.colors.surface,
                borderColor: theme.colors.userMessageBorder,
                color: theme.colors.text,
              }}
              rows={4}
            />
          </div>

          <div>
            <label
              className="block text-sm font-medium mb-2"
              style={{ color: theme.colors.text }}
            >
              Model
            </label>
            <select
              value={formData.model}
              onChange={(e) =>
                setFormData({ ...formData, model: e.target.value })
              }
              className="w-full px-4 py-2 rounded-lg border transition-all duration-200"
              style={{
                backgroundColor: theme.colors.surface,
                borderColor: theme.colors.userMessageBorder,
                color: theme.colors.text,
              }}
            >
              <option value="gpt-4">GPT-4</option>
              <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
            </select>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="isPublic"
              checked={formData.isPublic}
              onChange={(e) =>
                setFormData({ ...formData, isPublic: e.target.checked })
              }
              className="mr-2"
            />
            <label
              htmlFor="isPublic"
              className="text-sm font-medium"
              style={{ color: theme.colors.text }}
            >
              Make this agent public
            </label>
          </div>

          <div className="flex justify-end space-x-4 mt-6">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {initialData ? "Update" : "Create"}
            </Button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default AgentsPage;
