// components/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import agentReducer from "./slices/agentsSlice";
import { socketMiddleware } from "./socketMiddleware";
import { setupInterceptors } from "../../utils/axiosConfig";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    agents: agentReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(socketMiddleware),
});

setupInterceptors(store);