import React from "react";
import { motion } from "framer-motion";
import {
  ClipboardIcon,
  CodeIcon,
  ChatIcon,
  InformationCircleIcon,
  DatabaseIcon,
  ShieldCheckIcon,
} from "@heroicons/react/outline";
import { useTheme } from "../utils/themeProvider";
import { ArrowRight } from "lucide-react";

export const prompts = [
  {
    title: "Build an AI Chatbot",
    icon: <InformationCircleIcon className="h-8 w-8 text-blue-500" />,
    action: "buildAIChatbot",
    description:
      "Create a functional AI chatbot using Python and a simple NLP library.",
    prompt:
      "Can you guide me through building a functional AI chatbot using Python and a simple NLP library?",
  },
  {
    title: "Develop a Smart Contract",
    icon: <CodeIcon className="h-8 w-8 text-green-500" />,
    action: "developSmartContract",
    description:
      "Write and deploy a smart contract on the Ethereum blockchain using Solidity.",
    prompt:
      "Can you help me write and deploy a smart contract on the Ethereum blockchain using Solidity?",
  },
  {
    title: "Set Up a Blockchain Network",
    icon: <DatabaseIcon className="h-8 w-8 text-purple-500" />,
    action: "setUpBlockchainNetwork",
    description: "Launch a private blockchain network and add nodes to it.",
    prompt:
      "Can you provide steps to launch a private blockchain network and add nodes to it?",
  },
  {
    title: "Implement CI/CD Pipeline",
    icon: <ClipboardIcon className="h-8 w-8 text-yellow-500" />,
    action: "implementCICD",
    description:
      "Set up a continuous integration and deployment (CI/CD) pipeline for a project.",
    prompt:
      "Can you guide me through setting up a continuous integration and deployment (CI/CD) pipeline for a project?",
  },
  {
    title: "Secure a Web Application",
    icon: <ShieldCheckIcon className="h-8 w-8 text-red-500" />,
    action: "secureWebApp",
    description:
      "Apply best practices to secure a web application from common threats.",
    prompt:
      "Can you provide best practices to secure a web application from common threats?",
  },
  {
    title: "Conduct a Code Review",
    icon: <ChatIcon className="h-8 w-8 text-teal-500" />,
    action: "conductCodeReview",
    description:
      "Review code for optimization, security, and adherence to best practices.",
    prompt:
      "Can you explain how to conduct a code review for optimization, security, and adherence to best practices?",
  },
];

export const PromptCards = ({ onCardClick }) => {
  const { theme } = useTheme();

 
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto px-4">
      {prompts.map((prompt, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          whileHover={{
            scale: 1.02,
            backgroundColor: theme.colors.surface + 'F0',
          }}
          whileTap={{ scale: 0.98 }}
          onClick={() => onCardClick(prompt.prompt)}
          className="group relative overflow-hidden rounded-2xl cursor-pointer"
          style={{
            backgroundColor: theme.colors.surface,
            border: `1px solid ${theme.colors.border}`,
          }}
        >
          <div className="p-6">
            <div className="flex items-start gap-4">
              <div
                className="p-3 rounded-xl transition-all duration-200 group-hover:scale-110"
                style={{ backgroundColor: theme.colors.hover + '30' }}
              >
                {React.cloneElement(prompt.icon, {
                  className: "h-6 w-6",
                  style: { color: theme.colors.primary },
                })}
              </div>
              <div className="flex-1">
                <h3
                  className="text-lg font-semibold mb-2 group-hover:text-primary transition-colors duration-200"
                  style={{ color: theme.colors.text }}
                >
                  {prompt.title}
                </h3>
                <p
                  className="text-sm mb-4"
                  style={{ color: theme.colors.text + '90' }}
                >
                  {prompt.description}
                </p>
              </div>
            </div>
            
            <div className="flex items-center gap-2 text-sm font-medium mt-2" 
              style={{ color: theme.colors.primary }}>
              <span>Try prompt</span>
              <ArrowRight className="w-4 h-4 transition-transform duration-200 group-hover:translate-x-1" />
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );

};
