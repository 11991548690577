import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles, Crown, X } from 'lucide-react';

export const EnhancedCustomAlert = ({ onClose, children, theme, toggleUpgradeModal }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
    className="fixed bottom-[150px] sm:bottom-[150px] left-0 right-0 mx-auto px-4 z-50"
    style={{
      maxWidth: 'min(calc(100% - 32px), 740px)',
    }}
  >
    <motion.div
      className="rounded-xl backdrop-blur-md p-3 sm:p-4 shadow-lg"
      style={{
        backgroundColor: `${theme.colors.surface}95`,
        border: `1px solid ${theme.colors.primary}30`,
        boxShadow: `0 8px 32px -4px ${theme.colors.primary}30`
      }}
    >
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 sm:gap-6">
        <div className="flex items-center gap-3 w-full sm:w-auto">
          <div 
            className="p-2 rounded-lg shrink-0" 
            style={{ backgroundColor: `${theme.colors.primary}20` }}
          >
            <Crown className="h-5 w-5" style={{ color: theme.colors.primary }} />
          </div>
          <div 
            className="text-sm font-medium flex-1 sm:flex-none" 
            style={{ color: theme.colors.text }}
          >
            {children}
          </div>
        </div>
        <div className="flex items-center gap-3 w-full sm:w-auto">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={toggleUpgradeModal}
            className="flex items-center justify-center gap-2 px-4 py-2 rounded-lg flex-1 sm:flex-none"
            style={{
              background: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
              boxShadow: `0 2px 8px ${theme.colors.primary}30`,
            }}
          >
            <Sparkles className="w-4 h-4 text-white" />
            <span className="text-sm font-medium text-white whitespace-nowrap">Upgrade Now</span>
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClose}
            className="p-2 rounded-lg shrink-0"
            style={{ backgroundColor: `${theme.colors.surface}90` }}
          >
            <X className="w-5 h-5" style={{ color: theme.colors.text }} />
          </motion.button>
        </div>
      </div>
    </motion.div>
  </motion.div>
);