import React, { useEffect, useState } from "react";
import LoadingComponent from "./LoadingComponent";
import { Toast } from "./Toast";
import { useAuth } from "../utils/authProvider";
import { useNavigate } from "react-router-dom";
import { isVSCode } from "../utils/environment";
import Switch from "./Switch";

export default function Settings() {
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [addToCodeAIEnabled, setAddToCodeAIEnabled] = useState(false);

  const { token, settings, switchModel, currentModel } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    const loadSettings = async () => {
      if (isVSCode()) {
        const vscode = window.acquireVsCodeApi();

        const handleMessage = (event) => {
          const message = event.data;
          if (message.command === "updateConfiguration") {
            if (message.setting === "addToCodeAIEnabled") {
              setAddToCodeAIEnabled(message.value);
            } 
            setLoading(false);
          }
        };

        window.addEventListener("message", handleMessage);

        // Request configuration settings
        vscode.postMessage({
          command: "getConfiguration",
          setting: "addToCodeAIEnabled",
        });

      } else {
        setLoading(false);
      }
    };
    loadSettings();
  }, []);

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const handleModelChange = async (model) => {
    switchModel(model);
    if (isVSCode()) {
      const vscode = window.acquireVsCodeApi();
      vscode.postMessage({
        command: "setModel",
        model,
      });
    }
  };

  const handleSwitchChange = (setting, value) => {
    if (setting === "addToCodeAIEnabled") {
      setAddToCodeAIEnabled(value);
    } 

    if (isVSCode()) {
      const vscode = window.acquireVsCodeApi();
      vscode.postMessage({
        command: "updateConfiguration",
        setting,
        value,
      });
    } else {
      localStorage.setItem(setting, value.toString());
    }
    setShowToast(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-900 dark:bg-gray-900 flex items-center justify-center">
        <LoadingComponent />
      </div>
    );
  }

  if (!settings) {
    return (
      <div className="min-h-screen bg-slate-900 dark:bg-gray-900 flex items-center justify-center text-white">
        <p>Unable to load settings. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-slate-900 dark:bg-gray-900 flex flex-col justify-between z-10">
      <div className="mx-auto w-full max-w-2xl px-6 py-10 sm:py-10 lg:px-8 lg:py-10 text-white">
        <div className="flex-1 p-6 pt-0">
          <div className="p-4">
            <h1 className="mb-10 text-xl font-bold">Settings</h1>
            <div>
              <div className="mb-8">
                <label
                  htmlFor="defaultModel"
                  className="text-md mb-4 block font-medium leading-6 text-gray-200"
                >
                  Default Model
                </label>
                <select
                  id="defaultModel"
                  value={currentModel}
                  onChange={(e) => handleModelChange(e.target.value)}
                  className="bg-slate-700 block w-full rounded-md pl-4 py-2 pr-4 text-md text-gray-100 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  {settings.models && settings.models.map((model) => (
                    <option key={model} value={model} className="text-md">
                      {model.toString().toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              {isVSCode() && (
                <div className="mb-8">
                  <label
                    htmlFor="addToCodeAIEnabled"
                    className="text-md mb-4 block font-medium leading-6 text-gray-200"
                  >
                    Enable Add to CodeAI
                  </label>
                  <div className="flex items-center mb-4">
                    <Switch
                      checked={addToCodeAIEnabled}
                      onChange={(checked) => handleSwitchChange("addToCodeAIEnabled", checked)}
                    />
                    <label className="ml-2 text-sm font-medium leading-6 text-gray-200">
                      Enable the "Add to CodeAI" option
                    </label>
                  </div>
                </div>
              )}
              {showToast && (
                <Toast
                  title={"Settings Updated"}
                  show={showToast}
                  onClose={handleCloseToast}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}