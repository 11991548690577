import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { useSpring, useTransition } from "react-spring";
import { motion, AnimatePresence } from "framer-motion";
import {
  SendHorizontal,
  StopCircle,
  X,
  SparklesIcon,
  CheckIcon,
  Sparkles,
  Command,
  ArrowUp,
  ChevronDown,
} from "lucide-react";
import {
  addMessageToHistory,
  decrementRequestsRemaining,
  updateRequestsRemaining,
} from "./store/slices/authSlice";
import { models } from "../utils/models";

import { PromptCards } from "./PromptCard";
import ChatMessage from "./ChatMessage";
import { useTheme } from "../utils/themeProvider";
import WelcomeSection from "./WelcomeSection";
import { useNavigate } from "react-router-dom";
import ModernChatForm from "./ChatInput";
import { EnhancedCustomAlert } from "./CustomAlert";
import { SubscriptionError } from "./SubscriptionError";
import { MonthlyLimitReached } from "./MonthlyLimitReached";

export default function ChatComponent({
  upgradeModalVisible,
  toggleUpgradeModal,
}) {
  const [messageInput, setMessageInput] = useState("");
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [streaming, setStreaming] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showAlert, setShowAlert] = useState(true);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const {
    token,
    user,
    settings,
    currentModel,
    chatHistory,
    requestsRemaining,
  } = useSelector((state) => state.auth);

  const endOfMessagesRef = useRef(null);
  const socketRef = useRef(null);
  const chatContainerRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Shift") {
      setIsShiftPressed(true);
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!streaming) {
        sendMessage();
      }
    }
  };

  const navigate = useNavigate();
  const handleExploreAgents = () => {
    // Open agents page in new tab
    navigate("/agents");
  };

  const handleStartChatting = () => {
    const chatInput = document.getElementById("chat-input");
    if (chatInput) {
      // First ensure the input is visible
      chatInput.style.display = "block";

      // Add a subtle highlight animation
      chatInput.classList.add("animate-focus-border");

      // Smooth scroll to input
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });

      // Focus after scroll with a slight delay for smooth animation
      setTimeout(() => {
        chatInput.focus();

        setTimeout(() => {
          chatInput.classList.remove("animate-focus-border");
        }, 2000);
      }, 500);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Shift") {
      setIsShiftPressed(false);
    }
  };

  const isFreeUser = settings?.plan
    ? settings.plan.toLowerCase() === "free" ||
      settings.plan.toLowerCase() === "explorer"
    : "free";

  const messageTransitions = useTransition(chatHistory, {
    keys: (message) => message.id,
    from: { opacity: 0, transform: "translateY(20px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-20px)" },
    config: { tension: 300, friction: 20 },
  });

  const scrollButtonSpring = useSpring({
    opacity: isAtBottom ? 0 : 1,
    transform: isAtBottom
      ? "scale(0.8) translateY(20px)"
      : "scale(1) translateY(0px)",
    config: { tension: 300, friction: 20 },
  });

  const handleChatgptResChunk = useCallback(
    (data, socket) => {
      if (!data || !data.content) return;

      const { streamId, content, requestsRemaining } = data;
      if (content.trim() === "[DONE]") {
        setLoadingMessageId(null);
        setStreaming(false);
        socket.disconnect();
        scrollToBottom();
        if (requestsRemaining !== undefined) {
          dispatch(updateRequestsRemaining(requestsRemaining));
        }
        return;
      }

      const existingIndex = chatHistory.findIndex((msg) => msg.id === streamId);

      if (existingIndex !== -1) {
        const updatedMessage = {
          ...chatHistory[existingIndex],
          text: chatHistory[existingIndex].text + content,
          type: "response",
        };
        dispatch(addMessageToHistory(updatedMessage));
      } else {
        const newMessage = {
          id: streamId,
          text: content,
          type: "response",
        };
        dispatch(addMessageToHistory(newMessage));
      }

      if (isAtBottom) {
        scrollToBottom();
      }
    },
    [chatHistory, dispatch, isAtBottom]
  );

  const sendMessage = useCallback(
    async (message = messageInput) => {
      if (
        !message ||
        typeof message !== "string" ||
        !message.trim() ||
        requestsRemaining <= 0
      )
        return;

      const messageId = uuidv4();

      const newMessage = {
        id: messageId,
        text: message.trim(),
        type: "user",
      };
      dispatch(addMessageToHistory(newMessage));

      const formattedChatHistory = [...chatHistory, newMessage].map((msg) => ({
        role:
          msg.type === "user" || msg.type === "user-code"
            ? "user"
            : "assistant",
        content: msg.text,
      }));

      const socket = io("https://api.codeai.studio", {
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        secure: true,
        transports: ["websocket"],
      });

      socketRef.current = socket;

      socket.on("connect", () => {
        socket.emit("startCodeAIChat", {
          chatHistory: formattedChatHistory,
          chat: message,
          streamId: messageId,
          userId: user,
        });

        setLoadingMessageId(messageId);
        setStreaming(true);
        setMessageInput("");
        dispatch(decrementRequestsRemaining());
      });

      socket.on("chatgptResChunk", (data) =>
        handleChatgptResChunk(data, socket)
      );

      socket.on("resError", (data) => {
        console.error("Error:", data.error);
        alert("An error occurred");
        socket.disconnect();
        setStreaming(false);
      });

      scrollToBottom();
    },
    [
      messageInput,
      user,
      chatHistory,
      dispatch,
      handleChatgptResChunk,
      requestsRemaining,
    ]
  );

  const stopStreaming = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      setStreaming(false);
      setLoadingMessageId(null);
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
    }
  };

  const scrollToBottom = () => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleUpgrade = () => {
    window.open(
      "https://www.codeai.studio/dashboard/upgrade",
      "_blank",
      "noopener,noreferrer"
    );
    toggleUpgradeModal();
  };

  return (
    <div
      className="h-full flex flex-col"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.text,
      }}
    >
      <div className="flex-1 overflow-hidden flex flex-col">
        <div
          ref={chatContainerRef}
          className="flex-1 overflow-y-auto scrollbar-thin"
          style={{ scrollbarColor: `${theme.colors.primary} transparent` }}
          onScroll={handleScroll}
        >
          <div className="max-w-4xl mx-auto p-4">
            {chatHistory.length === 0 ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex flex-col items-center justify-center min-h-[80vh]"
              >
                <WelcomeSection
                  theme={theme}
                  onExploreAgents={handleExploreAgents}
                  onStartChat={handleStartChatting}
                />
                <PromptCards onCardClick={sendMessage} />
              </motion.div>
            ) : (
              messageTransitions((style, item) => (
                <motion.div style={style} key={item.id} className="mb-4">
                  <ChatMessage
                    msg={item}
                    user={user}
                    isLoading={loadingMessageId === item.id}
                  />
                </motion.div>
              ))
            )}
            <div ref={endOfMessagesRef} />
          </div>
        </div>

        {/* Upgrade Modal */}
        <AnimatePresence>
          {upgradeModalVisible && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex items-center justify-center z-50"
              onClick={toggleUpgradeModal}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0, y: 50 }}
                animate={{ scale: 1, opacity: 1, y: 0 }}
                exit={{ scale: 0.9, opacity: 0, y: 50 }}
                transition={{ type: "spring", damping: 25, stiffness: 300 }}
                className="p-8 rounded-2xl shadow-2xl max-w-md w-full mx-4"
                style={{
                  backgroundColor: theme.colors.surface,
                  borderColor: theme.colors.primary,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="text-center">
                  <motion.div
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <SparklesIcon className="h-12 w-12 text-yellow-400 mx-auto mb-4" />
                  </motion.div>
                  <h3 className="text-2xl font-bold mb-4 text-white">
                    Unlock Pro Features
                  </h3>
                  <p className="text-slate-300 mb-6">
                    Take your experience to the next level with our Pro plan:
                  </p>
                  <ul className="text-left text-slate-300 mb-6 space-y-2">
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                      Access to all advanced AI models
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                      Unlimited requests and higher rate limits
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                      Priority support and early feature access
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col space-y-3">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleUpgrade}
                    className="w-full py-3 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-lg hover:from-purple-600 hover:to-pink-600 transition duration-200 flex items-center justify-center shadow-md text-lg font-semibold"
                  >
                    <SparklesIcon className="h-5 w-5 mr-2" />
                    <span>Upgrade to Pro Now</span>
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={toggleUpgradeModal}
                    className="w-full py-2 bg-transparent text-slate-400 rounded-lg hover:bg-slate-700/50 transition duration-200 text-sm"
                  >
                    Maybe Later
                  </motion.button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        {/* Scroll to Bottom Button */}
        <motion.div
          style={scrollButtonSpring}
          className="fixed bottom-32 right-4 z-20 sm:bottom-34 sm:right-6"
        >
          <motion.button
            whileHover={{
              scale: 1.05,
              boxShadow: `0 8px 32px -4px ${theme.colors.primary}40`,
            }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToBottom}
            className="p-3 rounded-xl backdrop-blur-sm"
            style={{
              background: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
              border: `1px solid ${theme.colors.primary}30`,
            }}
            title="Scroll to Bottom"
          >
            <motion.div
              animate={{ y: [0, 3, 0] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <ChevronDown className="h-5 w-5 text-white" />
            </motion.div>
          </motion.button>
        </motion.div>
        <AnimatePresence>
          {showAlert && isFreeUser && (
            <EnhancedCustomAlert
              onClose={() => setShowAlert(false)}
              theme={theme}
              toggleUpgradeModal={toggleUpgradeModal}
            >
              Get 5x more usage and access to our latest models with Pro
            </EnhancedCustomAlert>
          )}
        </AnimatePresence>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
          className="z-10 shadow-2xl rounded-xl backdrop-blur-sm"
          style={{
            backgroundColor: `${theme.colors.surface}`,
            borderColor: theme.colors.primary,
          }}
        >
          {settings.error === "NO_ACTIVE_SUBSCRIPTION" ? (
            <SubscriptionError
              theme={theme}
              onUpgrade={() =>
                window.open(
                  "https://www.codeai.studio/dashboard/upgrade",
                  "_blank"
                )
              }
            />
          ) : requestsRemaining > 0 ? (
            <ModernChatForm
              messageInput={messageInput}
              setMessageInput={setMessageInput}
              streaming={streaming}
              sendMessage={sendMessage}
              stopStreaming={stopStreaming}
              handleKeyDown={handleKeyDown}
              handleKeyUp={handleKeyUp}
              isShiftPressed={isShiftPressed}
              currentModel={currentModel}
              theme={theme}
              models={models}
            />
          ) : (
            <MonthlyLimitReached 
            theme={theme}
            toggleUpgradeModal={toggleUpgradeModal}
          />
          )}
        </motion.div>
      </div>
    </div>
  );
}
