import React, { useState } from "react";
import { motion } from "framer-motion";
import { LockClosedIcon } from "@heroicons/react/outline";
import { CheckIcon, Bot, Loader2, Sparkles } from "lucide-react";
import { models } from "../utils/models";

export const ModelDropdown = ({
  isVisible,
  onClose,
  onModelSelect,
  currentModel,
  favoriteAgents,
  isLoading,
  isFreePlan,
  theme,
  toggleUpgradeModal,
}) => {
  const [activeTab, setActiveTab] = useState("models");

  const styles = {
    gradientBg: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
    hoverBg: theme.colors.hover + '20',
    selectedBg: theme.colors.hover + '40',
    cardBg: theme.colors.surface,
    textColor: theme.colors.text,
    mutedText: theme.colors.text + '60',
    border: theme.colors.hover + '40',
    shadowColor: theme.colors.primary + '20',
  };

  return (
    <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    transition={{ duration: 0.2, ease: "easeInOut" }}
    style={{
      background: styles.cardBg, // Solid background
      borderColor: styles.border,
      boxShadow: `0 20px 25px -5px ${styles.shadowColor}, 0 10px 10px -5px ${theme.colors.hover}30`,
      color: styles.textColor,
    }}
    className="fixed sm:absolute inset-x-0 sm:inset-auto top-[72px] sm:top-auto mx-auto sm:right-0 sm:mt-3 w-[calc(100%-32px)] sm:w-[340px] md:w-96 rounded-2xl overflow-hidden border sm:mx-0" >
      {/* Enhanced Tabs */}
      <div
        className="flex p-1.5 mx-2 my-2 rounded-xl"
        style={{ background: styles.hoverBg }}
      >
        {["Models", "Agents"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab.toLowerCase())}
            className={`flex-1 px-3 py-2.5 text-sm font-medium rounded-lg transition-all duration-200 ${
              activeTab === tab.toLowerCase() 
                ? "shadow-lg transform -translate-y-0.5" 
                : "hover:opacity-80"
            }`}
            style={{
              background: activeTab === tab.toLowerCase() 
                ? styles.gradientBg
                : 'transparent',
              color: activeTab === tab.toLowerCase()
                ? theme.colors.ctaText
                : styles.mutedText,
            }}
          >
            <div className="flex items-center justify-center gap-2">
              {tab === "Models" ? (
                <Sparkles 
                  size={16} 
                  className={activeTab === "models" ? "animate-pulse" : ""}
                  style={{ color: activeTab === "models" ? theme.colors.ctaText : styles.mutedText }}
                />
              ) : (
                <Bot 
                  size={16} 
                  className={activeTab === "agents" ? "animate-pulse" : ""}
                  style={{ color: activeTab === "agents" ? theme.colors.ctaText : styles.mutedText }}
                />
              )}
              {tab}
            </div>
          </button>
        ))}
      </div>

      {/* Enhanced Content */}
      <div 
        className="max-h-[min(calc(100vh-180px),460px)] overflow-y-auto px-2 scrollbar-thin scrollbar-thumb-rounded-lg"
        style={{
          scrollbarColor: `${styles.border} transparent`
        }}
      >
        {activeTab === "models" && (
          <div className="space-y-3">
            {Object.entries(models.reduce((acc, model) => {
              if (!acc[model.category]) acc[model.category] = [];
              acc[model.category].push(model);
              return acc;
            }, {})).map(([category, categoryModels]) => (
              <div key={category} className="space-y-1.5">
                <div className="px-3 text-xs font-medium tracking-wide" style={{ color: styles.mutedText }}>
                  {category}
                </div>
                {categoryModels.map((model) => (
                <motion.button
                key={model.name}
                onClick={() => onModelSelect(model)}
                whileHover={{ scale: 0.995 }}
                whileTap={{ scale: 0.99 }}
                style={{
                  background: currentModel?.name.toLowerCase() === model.name.toLowerCase()
                    ? styles.selectedBg
                    : 'transparent',
                  borderLeft: currentModel?.name.toLowerCase() === model.name.toLowerCase()
                    ? `3px solid ${theme.colors.primary}`
                    : '3px solid transparent',
                  opacity: isFreePlan && model.name.toLowerCase() !== "gpt-3.5" ? 0.6 : 1,
                }}
                className={`flex items-start w-full px-3 py-2.5 rounded-xl transition-all duration-200 group
                  ${currentModel?.name.toLowerCase() === model.name.toLowerCase() 
                    ? 'ring-1 ring-inset ring-primary/20' 
                    : 'hover:bg-opacity-80'}`}
                disabled={isFreePlan && model.name.toLowerCase() !== "gpt-3.5"}
              >
                <div className="flex gap-3 min-w-0 flex-1">
                  <div className="relative flex-shrink-0 mt-0.5">
                    <div 
                      className={`p-1 rounded-lg transition-colors duration-200 group-hover:bg-opacity-80
                        ${currentModel?.name.toLowerCase() === model.name.toLowerCase() 
                          ? 'bg-primary/10' 
                          : ''}`}
                      style={{ 
                        background: currentModel?.name.toLowerCase() === model.name.toLowerCase()
                          ? `linear-gradient(135deg, ${theme.colors.primary}15, ${theme.colors.primary}05)`
                          : styles.hoverBg 
                      }}
                    >
                      <img
                        src={model.logo}
                        alt={`${model.name} Logo`}
                        className={`h-8 w-8 rounded-md object-cover ${
                          currentModel?.name.toLowerCase() === model.name.toLowerCase()
                            ? 'scale-105'
                            : ''
                        }`}
                      />
                    </div>
                    {isFreePlan && model.name.toLowerCase() !== "gpt-3.5" && (
                      <div 
                        className="absolute -top-1 -right-1 rounded-full p-1 shadow-sm"
                        style={{ background: styles.cardBg }}
                      >
                        <LockClosedIcon className="h-3 w-3" style={{ color: styles.mutedText }} />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-start min-w-0 flex-1">
                    <div className="w-full flex items-center justify-between gap-2">
                      <span className={`font-medium text-left ${
                        currentModel?.name.toLowerCase() === model.name.toLowerCase()
                          ? 'text-primary'
                          : ''
                      }`}>
                        {model.name}
                      </span>
                      {currentModel?.name.toLowerCase() === model.name.toLowerCase() && (
                        <div className="flex items-center gap-1">
                          <div 
                            className="h-1.5 w-1.5 rounded-full animate-pulse" 
                            style={{ background: theme.colors.primary }}
                          />
                          <CheckIcon 
                            className="h-5 w-5 flex-shrink-0" 
                            style={{ color: theme.colors.primary }} 
                          />
                        </div>
                      )}
                    </div>
                    <span 
                      className="text-xs text-left line-clamp-2" 
                      style={{ color: styles.mutedText }}
                    >
                      {model.description}
                    </span>
                  </div>
                </div>
              </motion.button>
                ))}
              </div>
            ))}
          </div>
        )}

        {activeTab === "agents" && (
          <div className="space-y-2">
            {isLoading ? (
              <div className="px-4 py-12 text-center">
                <Loader2 
                  className="h-8 w-8 animate-spin mx-auto mb-3" 
                  style={{ color: theme.colors.primary }} 
                />
                <div className="text-sm" style={{ color: styles.mutedText }}>
                  Loading your intelligent agents...
                </div>
              </div>
            ) : favoriteAgents?.length > 0 ? (
              favoriteAgents.map((agent) => (
                <motion.button
                key={agent.assistantId}
                onClick={() => onModelSelect(agent)}
                whileHover={{ scale: 0.995 }}
                whileTap={{ scale: 0.99 }}
                style={{
                  background: currentModel?.id === `assistants-${agent.assistantId}`
                    ? styles.selectedBg
                    : styles.hoverBg,
                  borderLeft: currentModel?.id === `assistants-${agent.assistantId}`
                    ? `3px solid ${theme.colors.primary}`
                    : '3px solid transparent',
                }}
                className={`flex w-full px-3 py-2.5 rounded-xl transition-all duration-200 group
                  ${currentModel?.id === `assistants-${agent.assistantId}`
                    ? 'ring-1 ring-inset ring-primary/20'
                    : 'hover:bg-opacity-80'}`}
              >
                  <div className="flex gap-3 min-w-0 flex-1">
                    <div
                      className="h-10 w-10 rounded-lg flex items-center justify-center flex-shrink-0 transition-colors duration-200 group-hover:bg-opacity-80"
                      style={{ background: styles.gradientBg }}
                    >
                      <Bot size={20} color={theme.colors.ctaText} />
                    </div>
                    <div className="flex flex-col items-start min-w-0 flex-1">
                      <div className="w-full flex items-center justify-between gap-2">
                        <span className="font-medium text-left">{agent.name}</span>
                        {currentModel?.id === `assistants-${agent.assistantId}` && (
                          <CheckIcon className="h-5 w-5 flex-shrink-0" style={{ color: theme.colors.primary }} />
                        )}
                      </div>
                      <span 
                        className="text-xs text-left" 
                        style={{ color: styles.mutedText }}
                      >
                        Powered by {agent.model}
                      </span>
                    </div>
                  </div>
                </motion.button>
              ))
            ) : (
              <div className="px-4 py-12 text-center">
                <Bot size={32} className="mx-auto mb-3" style={{ color: styles.mutedText }} />
                <div className="text-sm" style={{ color: styles.mutedText }}>
                  No favorite agents yet
                </div>
                <div className="text-xs mt-1" style={{ color: styles.mutedText }}>
                  Your custom agents will appear here
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Enhanced Upgrade prompt */}
      {isFreePlan && activeTab === "models" && (
        <div
          className="px-4 py-3 text-sm"
          style={{
            borderTop: `1px solid ${styles.border}`,
            background: `linear-gradient(to bottom, transparent, ${styles.selectedBg})`,
          }}
        >
          <button
            onClick={() => {
              onClose();
              toggleUpgradeModal();
            }}
            className="flex items-center justify-center w-full py-2.5 rounded-xl font-medium transition-all duration-200 hover:opacity-90 hover:scale-[0.99] transform"
            style={{ 
              background: styles.gradientBg,
              color: theme.colors.ctaText,
              boxShadow: `0 4px 6px -1px ${styles.shadowColor}`
            }}
          >
            Upgrade to Access Premium Models →
          </button>
        </div>
      )}
    </motion.div>
  );
};

export const ModelDisplay = ({ model, theme }) => {
    const styles = {
      gradientBg: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
      hoverBg: theme.colors.hover + '20',
      mutedText: theme.colors.text + '60',
    };
  
    // Handle case where model is not an object
    const defaultModel = {
      name: 'gpt-3.5',
      type: 'openai',
      logo: models.find(m => m.name.toLowerCase() === 'gpt-3.5')?.logo || "default-logo-url"
    };
  
    // If model is not an object or is null/undefined, use default
    const safeModel = (typeof model === 'object' && model !== null) ? model : defaultModel;
  
    if (safeModel.type === "assistant") {
      return (
        <div className="flex items-center gap-2">
          <div
            className="h-8 w-8 rounded-lg flex items-center justify-center transition-transform duration-200 hover:scale-105"
            style={{ background: styles.gradientBg }}
          >
            <Bot size={18} color={theme.colors.ctaText} />
          </div>
          <span className="hidden sm:inline font-medium truncate">
            {safeModel.name}
          </span>
        </div>
      );
    }
  
    const modelInfo = models.find(m => 
      m.name.toLowerCase() === (safeModel?.name || 'gpt-3.5').toLowerCase()
    ) || {
      logo: safeModel?.logo || defaultModel.logo,
      category: "AI Model",
      name: safeModel?.name || defaultModel.name
    };
  
    return (
      <div className="flex items-center gap-2 max-w-full">
        <div className="relative h-8 w-8 flex-shrink-0 transition-transform duration-200 hover:scale-105">
          <div className="p-1 rounded-lg" style={{ background: styles.hoverBg }}>
            <img
              src={modelInfo.logo}
              alt={`${modelInfo.name} Logo`}
              className="h-full w-full rounded-md object-cover"
            />
          </div>
        </div>
        <div className="hidden sm:flex flex-col min-w-0">
          <span className="font-medium truncate">
            {modelInfo.name.toUpperCase()}
          </span>
        </div>
      </div>
    );
  };