import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, Zap } from "lucide-react";

export const SubscriptionError = ({ theme, onUpgrade }) => (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="p-8 rounded-2xl"
      style={{
        background: `linear-gradient(135deg, ${theme.colors.surface}, ${theme.colors.surface}90)`,
        border: `1px solid ${theme.colors.primary}20`,
        boxShadow: `0 8px 32px -4px ${theme.colors.primary}20`
      }}
    >
      <div className="flex flex-col items-center text-center gap-6">
        <motion.div
          animate={{ scale: [1, 1.1, 1] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="p-4 rounded-full"
          style={{ backgroundColor: `${theme.colors.primary}20` }}
        >
          <AlertTriangle className="h-8 w-8" style={{ color: theme.colors.primary }} />
        </motion.div>
        <div>
          <h3 className="text-xl font-bold mb-2" style={{ color: theme.colors.primary }}>
            No Active Subscription
          </h3>
          <p className="text-base mb-6" style={{ color: `${theme.colors.text}90` }}>
            Subscribe to a plan to unlock all of CodeAI's powerful features
          </p>
        </div>
        <motion.button
          whileHover={{ 
            scale: 1.02,
            boxShadow: `0 8px 32px -4px ${theme.colors.primary}40`
          }}
          whileTap={{ scale: 0.98 }}
          onClick={onUpgrade}
          className="flex items-center gap-3 px-6 py-3 rounded-xl"
          style={{
            background: `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
            boxShadow: `0 4px 16px ${theme.colors.primary}30`,
          }}
        >
          <Zap className="w-5 h-5 text-white" />
          <span className="text-base font-medium text-white">Choose Your Plan</span>
        </motion.button>
      </div>
    </motion.div>
  );