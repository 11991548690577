import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  SendHorizontal, 
  StopCircle, 
  Command, 
  ArrowUp,
  Sparkles,
  Code2,
  Wand2,
  FileCode,
  GitBranch,
  Terminal,
  Database,
  Shield,
  Settings,
  Rocket
} from 'lucide-react';

const commands = [
  { 
    cmd: '/code', 
    icon: <Code2 className="w-4 h-4" />,
    description: 'Generate code snippets',
    examples: ['function', 'class', 'component']
  },
  { 
    cmd: '/explain', 
    icon: <Wand2 className="w-4 h-4" />,
    description: 'Explain code or concept',
    examples: ['code', 'algorithm', 'pattern']
  },
  { 
    cmd: '/refactor', 
    icon: <FileCode className="w-4 h-4" />,
    description: 'Refactor code for better quality',
    examples: ['optimize', 'clean', 'improve']
  },
  { 
    cmd: '/git', 
    icon: <GitBranch className="w-4 h-4" />,
    description: 'Git operations help',
    examples: ['commit', 'merge', 'rebase']
  },
  { 
    cmd: '/debug', 
    icon: <Terminal className="w-4 h-4" />,
    description: 'Debug code issues',
    examples: ['error', 'bug', 'fix']
  },
  { 
    cmd: '/db', 
    icon: <Database className="w-4 h-4" />,
    description: 'Database queries and schema',
    examples: ['query', 'schema', 'model']
  },
  { 
    cmd: '/security', 
    icon: <Shield className="w-4 h-4" />,
    description: 'Security best practices',
    examples: ['audit', 'encrypt', 'protect']
  },
  { 
    cmd: '/settings', 
    icon: <Settings className="w-4 h-4" />,
    description: 'Change conversation settings',
    examples: ['model', 'context', 'tone']
  }
];

const ModernChatForm = ({
  messageInput,
  setMessageInput,
  streaming,
  sendMessage,
  stopStreaming,
  handleKeyDown,
  handleKeyUp,
  isShiftPressed,
  currentModel,
  theme,
  models
}) => {
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [showCommands, setShowCommands] = useState(false);

  const handleCommandClick = (cmd, example) => {
    setMessageInput(`${cmd} ${example}`);
    setShowCommands(false);
    document.getElementById('chat-input').focus();
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 30
      }
    }
  };

  const commandVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 30
      }
    },
    hover: {
      scale: 1.02,
      backgroundColor: `${theme.colors.primary}15`,
    }
  };

  return (
    <motion.form
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="relative w-full max-w-4xl mx-auto p-6"
      onSubmit={(e) => {
        e.preventDefault();
        if (!streaming) {
          sendMessage();
        }
      }}
    >
      <div 
        className="relative rounded-2xl p-4"
        style={{
          backgroundColor: theme.colors.surface,
          border: `1px solid ${theme.colors.primary}20`,
          boxShadow: `0 8px 32px -4px ${theme.colors.primary}15`
        }}
      >
        {/* Input Group */}
        <div className="relative group">
          <motion.div
            animate={{
              boxShadow: streaming 
                ? `0 0 0 2px ${theme.colors.accent}` 
                : `0 0 0 1px ${theme.colors.primary}30`
            }}
            className="relative rounded-xl overflow-hidden"
          >
            <textarea
              id="chat-input"
              value={messageInput}
              onChange={(e) => {
                setMessageInput(e.target.value);
                setShowCommands(e.target.value.startsWith('/'));
              }}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              className="w-full resize-none py-4 px-5 pr-36 text-base rounded-xl transition-all duration-300"
              style={{
                backgroundColor: `${theme.colors.surface}`,
                color: theme.colors.text,
                outline: 'none',
                minHeight: "64px",
                maxHeight: "200px",
              }}
              placeholder="Start asking CodeAI all your questions..."
              rows="1"
              required
            />

            {/* Fancy Focus Effect */}
            <motion.div
              className="absolute inset-0 pointer-events-none"
              animate={{
                opacity: streaming ? 0.1 : 0.05
              }}
              style={{
                background: `radial-gradient(circle at 50% 0%, ${theme.colors.primary}, transparent 75%)`
              }}
            />
          </motion.div>

          {/* Action Buttons */}
          <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2">
            {/* Model Badge */}
            <motion.div 
              whileHover={{ scale: 1.05 }}
              className="flex items-center gap-2 px-3 py-1.5 rounded-lg"
              style={{ 
                backgroundColor: `${theme.colors.primary}15`,
                border: `1px solid ${theme.colors.primary}20`,
              }}
            >
              {(() => {
                const modelName = currentModel?.name || "gpt-3.5";
                const modelInfo = models.find(
                  (m) => m.name.toLowerCase() === modelName.toString().toLowerCase()
                );

                return (
                  <>
                    <img
                      src={modelInfo?.logo}
                      alt="Model Logo"
                      className="h-4 w-4 rounded-md"
                    />
                    <span 
                      className="text-xs font-medium"
                      style={{ color: theme.colors.primary }}
                    >
                      {modelName.toString().toUpperCase()}
                    </span>
                  </>
                );
              })()}
            </motion.div>

            {/* Send/Stop Button */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (streaming) {
                  stopStreaming();
                } else {
                  sendMessage();
                }
              }}
              className="flex items-center justify-center rounded-lg w-10 h-10"
              style={{
                background: streaming 
                  ? theme.colors.accent
                  : `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primary}90)`,
                boxShadow: `0 2px 8px ${theme.colors.primary}30`,
              }}
            >
              <AnimatePresence mode="wait">
                <motion.div
                  key={streaming ? 'stop' : 'send'}
                  initial={{ scale: 0, rotate: -90 }}
                  animate={{ scale: 1, rotate: 0 }}
                  exit={{ scale: 0, rotate: 90 }}
                  transition={{ type: "spring", stiffness: 400, damping: 20 }}
                >
                  {streaming ? (
                    <StopCircle className="w-5 h-5 text-white" />
                  ) : (
                    <SendHorizontal className="w-5 h-5 text-white" />
                  )}
                </motion.div>
              </AnimatePresence>
            </motion.button>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="flex justify-between items-center mt-3 px-2">
          <div className="flex items-center gap-3">
            {/* <div className="flex items-center gap-1.5 px-2 py-1 rounded-md"
              style={{ backgroundColor: `${theme.colors.primary}10` }}>
              <Command className="w-3.5 h-3.5" style={{ color: theme.colors.primary }} />
              <span className="text-xs font-medium" style={{ color: theme.colors.primary }}>
                Type / for commands
              </span>
            </div> */}
            <motion.div
              animate={{ opacity: isShiftPressed ? 1 : 0.6 }}
              className="flex items-center gap-1.5"
            >
              <ArrowUp className="w-3.5 h-3.5" style={{ color: theme.colors.text }} />
              <span className="text-xs" style={{ color: theme.colors.text }}>
                {isShiftPressed ? "Release to send" : "Press Enter to send"}
              </span>
            </motion.div>
          </div>

          <motion.div
            animate={{ opacity: messageInput.length > 0 ? 1 : 0.6 }}
            className="text-xs font-medium px-2 py-1 rounded-md"
            style={{ 
              backgroundColor: `${theme.colors.primary}10`,
              color: theme.colors.primary 
            }}
          >
            {messageInput.length} characters
          </motion.div>
        </div>
      </div>

      {/* Commands Popup */}
      {/* <AnimatePresence>
        {showCommands && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute left-6 bottom-full mb-2 p-3 rounded-xl w-96"
            style={{
              backgroundColor: theme.colors.surface,
              border: `1px solid ${theme.colors.border}`,
              boxShadow: `0 4px 20px ${theme.colors.primary}20`,
            }}
          >
            <div className="flex items-center gap-2 mb-3">
              <Rocket className="w-4 h-4" style={{ color: theme.colors.primary }} />
              <span className="text-sm font-medium" style={{ color: theme.colors.primary }}>
                Available Commands
              </span>
            </div>
            <div className="space-y-1">
              {commands.map((cmd) => (
                <motion.div
                  key={cmd.cmd}
                  variants={commandVariants}
                  initial="hidden"
                  animate="visible"
                  whileHover="hover"
                  className="p-2 rounded-lg cursor-pointer"
                  onClick={() => setSelectedCommand(cmd)}
                >
                  <div className="flex items-center gap-3">
                    <div style={{ color: theme.colors.primary }}>{cmd.icon}</div>
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <span className="font-medium" style={{ color: theme.colors.text }}>
                          {cmd.cmd}
                        </span>
                        <span className="text-xs" style={{ color: `${theme.colors.text}80` }}>
                          {cmd.description}
                        </span>
                      </div>
                      <div className="flex gap-2 mt-1">
                        {cmd.examples.map((example) => (
                          <motion.span
                            key={example}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCommandClick(cmd.cmd, example);
                            }}
                            className="text-xs px-2 py-1 rounded-md"
                            style={{ 
                              backgroundColor: `${theme.colors.primary}15`,
                              color: theme.colors.primary
                            }}
                          >
                            {example}
                          </motion.span>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}
    </motion.form>
  );
};

export default ModernChatForm;