import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";
import { Toast } from "./Toast";
import Switch from "./Switch";
import { clearProfileUpdateStatus, selectProfileUpdateError, selectProfileUpdateStatus, switchModel, updateUserProfile } from "./store/slices/authSlice";
import { isVSCode } from "../utils/environment";
import { useTheme } from "../utils/themeProvider";
import ThemeSelector from "./ThemeSelector";
import { Edit2, Settings as SettingsIcon, User } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

export default function Settings() {
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Settings Updated");
  const [addToCodeAIEnabled, setAddToCodeAIEnabled] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [profileErrors, setProfileErrors] = useState({});
  const [profileData, setProfileData] = useState({
    username: "",
    first_name: "",
    last_name: ""
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { theme } = useTheme();
  const { token, settings, currentModel, user } = useSelector((state) => state.auth);
  const profileUpdateStatus = useSelector(selectProfileUpdateStatus);
  const profileUpdateError = useSelector(selectProfileUpdateError);


  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    if (user) {
      setProfileData({
        username: user.username || "",
        first_name: user.first_name || "",
        last_name: user.last_name || ""
      });
    }
  }, [token, navigate, user]);

  useEffect(() => {
    return () => {
      dispatch(clearProfileUpdateStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (profileUpdateStatus === 'succeeded') {
      setToastMessage("Profile updated successfully");
      setShowToast(true);
      setIsEditingProfile(false);
    } else if (profileUpdateStatus === 'failed') {
      setToastMessage("Failed to update profile");
      setShowToast(true);
    }
  }, [profileUpdateStatus]);

  useEffect(() => {
    const loadSettings = async () => {
      if (isVSCode()) {
        const vscode = window.acquireVsCodeApi();
        const handleMessage = (event) => {
          const message = event.data;
          if (message.command === "updateConfiguration") {
            if (message.setting === "addToCodeAIEnabled") {
              setAddToCodeAIEnabled(message.value);
            }
            setLoading(false);
          }
        };

        window.addEventListener("message", handleMessage);
        vscode.postMessage({
          command: "getConfiguration",
          setting: "addToCodeAIEnabled",
        });
      } else {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  const handleModelChange = async (model) => {
    dispatch(switchModel(model));
    if (isVSCode()) {
      const vscode = window.acquireVsCodeApi();
      vscode.postMessage({ command: "setModel", model });
    }
  };

  const handleSwitchChange = (setting, value) => {
    if (setting === "addToCodeAIEnabled") {
      setAddToCodeAIEnabled(value);
    }

    if (isVSCode()) {
      const vscode = window.acquireVsCodeApi();
      vscode.postMessage({
        command: "updateConfiguration",
        setting,
        value,
      });
    } else {
      localStorage.setItem(setting, value.toString());
    }
    setShowToast(true);
  };

  const handleCloseToast = () => {
    setShowToast(false);
    if (profileUpdateStatus === 'succeeded' || profileUpdateStatus === 'failed') {
      dispatch(clearProfileUpdateStatus());
    }
  };

  const handleProfileSubmit = async () => {
    dispatch(updateUserProfile({
      token,
      profileData
    }));
  };

  const handleProfileChange = (field, value) => {
    setProfileData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCancelEdit = () => {
    setIsEditingProfile(false);
    dispatch(clearProfileUpdateStatus());
    // Reset to original values
    setProfileData({
      username: user?.username || "",
      first_name: user?.first_name || "",
      last_name: user?.last_name || ""
    });
  };


  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center" style={{ backgroundColor: theme.colors.background }}>
        <LoadingComponent />
      </div>
    );
  }

  if (!settings) {
    return (
      <div className="h-screen flex items-center justify-center" style={{ backgroundColor: theme.colors.background, color: theme.colors.text }}>
        <p className="text-lg font-medium">Unable to load settings. Please try again later.</p>
      </div>
    );
  }

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
  };

  return (
    <div 
      className="w-full h-screen overflow-y-auto"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.text,
      }}
    >
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex items-center justify-center gap-3 mb-12"
        >
          <SettingsIcon className="w-8 h-8" style={{ color: theme.colors.primary }} />
          <h1 
            className="text-3xl font-bold text-center"
            style={{ color: theme.colors.primary }}
          >
            Settings
          </h1>
        </motion.div>

        <div className="space-y-8 mb-24">
          <motion.div 
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            style={{ backgroundColor: theme.colors.surface }}
          >
            <div className="p-8">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center gap-3">
                  <User className="w-6 h-6" style={{ color: theme.colors.primary }} />
                  <h2 className="text-xl font-semibold">Profile Settings</h2>
                </div>
                {!isEditingProfile && (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsEditingProfile(true)}
                    className="flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200"
                    style={{ 
                      backgroundColor: theme.colors.primary + '20',
                      color: theme.colors.primary 
                    }}
                  >
                    <Edit2 className="w-4 h-4" />
                    <span>Edit Profile</span>
                  </motion.button>
                )}
              </div>

              <AnimatePresence mode="wait">
                {isEditingProfile ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="space-y-6"
                  >
                    {/* Username field */}
                    <div>
                      <label className="block text-sm font-medium mb-2">Username</label>
                      <input
                        type="text"
                        value={profileData.username}
                        onChange={(e) => handleProfileChange('username', e.target.value)}
                        className="w-full px-4 py-3 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300 ease-in-out"
                        style={{
                          backgroundColor: theme.colors.background,
                          color: theme.colors.text,
                          borderColor: profileErrors.username ? theme.colors.error : theme.colors.primary,
                        }}
                      />
                      {profileErrors.username && (
                        <p className="mt-2 text-sm" style={{ color: theme.colors.error }}>
                          {profileErrors.username}
                        </p>
                      )}
                    </div>

                    {/* First Name field */}
                    <div>
                      <label className="block text-sm font-medium mb-2">First Name</label>
                      <input
                        type="text"
                        value={profileData.first_name}
                        onChange={(e) => handleProfileChange('first_name', e.target.value)}
                        className="w-full px-4 py-3 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300 ease-in-out"
                        style={{
                          backgroundColor: theme.colors.background,
                          color: theme.colors.text,
                          borderColor: profileErrors.first_name ? theme.colors.error : theme.colors.primary,
                        }}
                      />
                      {profileErrors.first_name && (
                        <p className="mt-2 text-sm" style={{ color: theme.colors.error }}>
                          {profileErrors.first_name}
                        </p>
                      )}
                    </div>

                    {/* Last Name field */}
                    <div>
                      <label className="block text-sm font-medium mb-2">Last Name</label>
                      <input
                        type="text"
                        value={profileData.last_name}
                        onChange={(e) => handleProfileChange('last_name', e.target.value)}
                        className="w-full px-4 py-3 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300 ease-in-out"
                        style={{
                          backgroundColor: theme.colors.background,
                          color: theme.colors.text,
                          borderColor: profileErrors.last_name ? theme.colors.error : theme.colors.primary,
                        }}
                      />
                      {profileErrors.last_name && (
                        <p className="mt-2 text-sm" style={{ color: theme.colors.error }}>
                          {profileErrors.last_name}
                        </p>
                      )}
                    </div>

                    <div className="flex justify-end gap-3 mt-6">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleCancelEdit}
                        className="px-4 py-2 rounded-lg text-sm font-medium"
                        style={{ 
                          backgroundColor: theme.colors.error + '20',
                          color: theme.colors.error
                        }}
                      >
                        Cancel
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleProfileSubmit}
                        className="px-4 py-2 rounded-lg text-sm font-medium text-white"
                        style={{ backgroundColor: theme.colors.primary }}
                      >
                        Save Changes
                      </motion.button>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="space-y-4"
                  >
                    <div className="flex justify-between py-3 px-4 rounded-lg" style={{ backgroundColor: theme.colors.background }}>
                      <span className="font-medium">Username</span>
                      <span>{profileData.username || "Not set"}</span>
                    </div>
                    <div className="flex justify-between py-3 px-4 rounded-lg" style={{ backgroundColor: theme.colors.background }}>
                      <span className="font-medium">First Name</span>
                      <span>{profileData.first_name}</span>
                    </div>
                    <div className="flex justify-between py-3 px-4 rounded-lg" style={{ backgroundColor: theme.colors.background }}>
                      <span className="font-medium">Last Name</span>
                      <span>{profileData.last_name}</span>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
          </div>

        <div className="space-y-8 mb-24">
          <motion.div 
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            style={{ backgroundColor: theme.colors.surface }}
          >
            <div className="p-8">
              <h2 className="text-xl font-semibold mb-6">Model Preferences</h2>
              <label htmlFor="defaultModel" className="block text-sm font-medium mb-2">
                Default Model
              </label>
              <select
                id="defaultModel"
                value={currentModel}
                onChange={(e) => handleModelChange(e.target.value)}
                className="w-full px-4 py-3 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300 ease-in-out"
                style={{
                  backgroundColor: theme.colors.background,
                  color: theme.colors.text,
                  borderColor: theme.colors.primary,
                }}
              >
                {settings.models?.map((model) => (
                  <option key={model} value={model}>
                    {model.toString().toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </motion.div>

          {isVSCode() && (
            <motion.div 
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              className="rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
              style={{ backgroundColor: theme.colors.surface }}
            >
              <div className="p-8">
                <h2 className="text-xl font-semibold mb-6">Integration Settings</h2>
                <div className="flex items-center justify-between">
                  <div>
                    <span className="text-lg block">Enable Add to CodeAI</span>
                    <span className="text-sm opacity-75">Allow CodeAI integration in your development environment</span>
                  </div>
                  <Switch
                    checked={addToCodeAIEnabled}
                    onChange={(checked) => handleSwitchChange("addToCodeAIEnabled", checked)}
                    className={`${
                      addToCodeAIEnabled ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    <span
                      className={`${
                        addToCodeAIEnabled ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                    />
                  </Switch>
                </div>
              </div>
            </motion.div>
          )}

          <motion.div 
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            style={{ backgroundColor: theme.colors.surface }}
          >
            <div className="p-8">
              <h2 className="text-xl font-semibold mb-6">Appearance</h2>
              <ThemeSelector />
            </div>
          </motion.div>
        </div>
      </div>

      {showToast && (
        <div className="fixed bottom-4 right-4 z-50">
          <Toast
            title="Settings Updated"
            show={showToast}
            onClose={handleCloseToast}
          />
        </div>
      )}
    </div>
  );
}