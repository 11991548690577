/* eslint-disable no-throw-literal */
import axiosInstance from "./axiosConfig";

// Helper function to create headers with token
const createHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// Fetch user data
export const getUser = async (token) => {
  try {
    const response = await axiosInstance.get("/user", createHeaders(token));
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

// Fetch settings data
export const getSettings = async (token) => {
  try {
    const response = await axiosInstance.get(
      "/key/settings",
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      error.response.status === 404 &&
      error.response.data === "Active subscription not found for this user."
    ) {
      return { error: "NO_ACTIVE_SUBSCRIPTION" };
    }
    console.error("Error fetching settings data:", error);
    throw error;
  }
};

// Update selected model
export const setModel = async (token, model) => {
  try {
    const response = await axiosInstance.post(
      "/key/token/set/model",
      { model },
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error setting model:", error);
    throw error;
  }
};

export const getAgents = async (token, type = "all", page = 1, limit = 10) => {
  try {
    let endpoint;
    switch (type) {
      case "my":
        endpoint = "/assistants/user";
        break;
      case "public":
        endpoint = "/assistants/public";
        break;
      case "favorites":
        endpoint = "/assistants/favorites";
        break;
      case "all":
        endpoint = "/assistants/all";
        break;
      default:
        endpoint = "/assistants/user";
        break;
    }
    const response = await axiosInstance.get(endpoint, {
      ...createHeaders(token),
      params: { page, limit },
    });
    const totalAgents = response.data.total;
    const totalPages = Math.ceil(totalAgents / limit);

    return {
      items: response.data.assistants,
      totalPages: totalPages,
      currentPage: response.data.page,
      totalAgents: totalAgents,
    };
  } catch (error) {
    console.error("Error fetching agents:", error);
    throw error;
  }
};

export const createNewAgent = async (token, formData) => {
  try {
    const response = await axiosInstance.post(
      "/assistants",
      formData,
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error creating agent:", error);
    throw error;
  }
};

export const updateExistingAgent = async (token, assistantId, formData) => {
  try {
    const response = await axiosInstance.put(
      "/assistants",
      {
        assistantId,
        ...formData,
      },
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error updating agent:", error);
    throw error;
  }
};

export const deleteExistingAgent = async (token, agentId) => {
  try {
    const response = await axiosInstance.delete(
      `/assistants/${agentId}`,
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting agent:", error);
    throw error;
  }
};

export const toggleStarAgent = async (token, agentId) => {
  try {
    const response = await axiosInstance.post(
      `/assistants/${agentId}/toggle-star`,
      {},
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error toggling star status:", error);
    throw error;
  }
};

export const getFavoriteAgents = async (token) => {
  try {
    const response = await axiosInstance.get(
      "/assistants/favorites",
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching favorite agents:", error);
    throw error;
  }
};

export const searchAgents = async (token, searchTerm) => {
  try {
    const response = await axiosInstance.get("/assistants/search", {
      ...createHeaders(token),
      params: { query: searchTerm },
    });
    return response.data;
  } catch (error) {
    console.error("Error searching agents:", error);
    throw error;
  }
};


export const updateProfile = async (token, profileData) => {
  try {
    const response = await axiosInstance.post(
      "/auth/updateuser",
      profileData,
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    // Handle specific error cases
    if (error.response?.status === 400) {
      throw {
        field: error.response.data.field,
        message: error.response.data.message,
      };
    }
    console.error("Error updating profile:", error);
    throw error;
  }
};