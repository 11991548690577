/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUser,
  getSettings,
  setModel,
  updateProfile,
} from "../../../utils/api";
import { isVSCode } from "../../../utils/environment";

const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
  USER: "user",
  SETTINGS: "settings",
  CHAT_HISTORY: "chatHistory",
  CURRENT_MODEL: "currentModel",
};

const updateVSCodeToken = (token) => {
  if (isVSCode) {
    // Only check isVSCode flag
    try {
      // The vscode global should already be defined at the top level for VS Code environments
      console.log("Updating VSCode token after successful login");
      vscode.postMessage({
        command: "token-update",
        token: token,
      });
    } catch (error) {
      console.error("Failed to update VSCode token:", error);
    }
  }
};

// Keep the existing vscode definition at the top level
const vscode = isVSCode && window.acquireVsCodeApi ? acquireVsCodeApi() : null;

export const authenticate = createAsyncThunk(
  "auth/authenticate",
  async (token, { dispatch, rejectWithValue }) => {
    try {
      const [userData, settingsData] = await Promise.all([
        getUser(token),
        getSettings(token),
      ]);
      if (!settingsData || !userData) {
        console.error("Settings data is null or undefined");
        dispatch({ type: "auth/logout" });
        return rejectWithValue("Invalid settings data");
      }
      
      updateVSCodeToken(token);
      return { token, user: userData.user, settings: settingsData };
    } catch (error) {
      dispatch({ type: "auth/logout" }); // Ensure logout on failure
      return rejectWithValue(error.response?.data || "Authentication failed");
    }
  }
);

export const switchModel = createAsyncThunk(
  "auth/switchModel",
  async ({ token, model }, { rejectWithValue }) => {
    try {
      await setModel(token, model);
      return model;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to switch model");
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "auth/updateProfile",
  async ({ token, profileData }, { rejectWithValue }) => {
    try {
      const response = await updateProfile(token, profileData);
      return response.user;
    } catch (error) {
      if (error.field) {
        // Handle validation errors
        return rejectWithValue({
          field: error.field,
          message: error.message,
        });
      }
      return rejectWithValue("Failed to update profile");
    }
  }
);

export const selectToken = (state) => state.auth.token;
export const selectProfileUpdateStatus = (state) =>
  state.auth.profileUpdateStatus;
export const selectProfileUpdateError = (state) =>
  state.auth.profileUpdateError;

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN),
    user: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) || null,
    settings:
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS)) || null,
    isConnected: false,
    isLoading: false,
    currentModel: {
      name: localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_MODEL) || "gpt-3.5",
      id: null,
      type: "openai",
      instructions: null,
    },
    hasSubscription: true,
    chatHistory:
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CHAT_HISTORY)) || [],
    requestsRemaining: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))?.request_balance || 0,
    profileUpdateStatus: "idle",
    profileUpdateError: null,
  },
  reducers: {
    logout: (state) => {
      Object.values(LOCAL_STORAGE_KEYS).forEach((key) =>
        localStorage.removeItem(key)
      );
      return {
        token: null,
        user: null,
        settings: null,
        isConnected: false,
        currentModel: "gpt-3.5",
        hasSubscription: true,
        chatHistory: [],
        requestsRemaining: 0,
      };
    },
    setConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    addMessageToHistory: (state, action) => {
      const message = action.payload;
      const index = state.chatHistory.findIndex((msg) => msg.id === message.id);
      if (index !== -1) {
        state.chatHistory[index].text += message.text;
      } else {
        state.chatHistory.push(message);
      }
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.CHAT_HISTORY,
        JSON.stringify(state.chatHistory)
      );
    },
    decrementRequestsRemaining: (state) => {
      state.requestsRemaining = Math.max(0, state.requestsRemaining - 1);
      
      // Update user object with new request balance
      if (state.user) {
        state.user.request_balance = state.requestsRemaining;
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER,
          JSON.stringify(state.user)
        );
      }
    },
    clearHistory: (state) => {
      state.chatHistory = [];
      localStorage.setItem(LOCAL_STORAGE_KEYS.CHAT_HISTORY, JSON.stringify([]));
    },
    updateRequestsRemaining: (state, action) => {
      state.requestsRemaining = action.payload;
      
      // Update user object with new request balance
      if (state.user) {
        state.user.request_balance = action.payload;
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER,
          JSON.stringify(state.user)
        );
      }
    },
    clearProfileUpdateStatus: (state) => {
      state.profileUpdateStatus = "idle";
      state.profileUpdateError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.settings = action.payload.settings;
        state.currentModel = action.payload.settings.selectedModel || "gpt-3.5";
        
        // Set requestsRemaining from user.request_balance
        state.requestsRemaining = action.payload.user.request_balance || 0;

        // Persist state in local storage
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, action.payload.token);
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER,
          JSON.stringify(action.payload.user)
        );
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.SETTINGS,
          JSON.stringify(action.payload.settings)
        );
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.CURRENT_MODEL,
          state.currentModel
        );
      })
      .addCase(authenticate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(switchModel.fulfilled, (state, action) => {
        state.currentModel = action.payload;
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_MODEL, action.payload);
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.profileUpdateStatus = "loading";
        state.profileUpdateError = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.profileUpdateStatus = "succeeded";
        state.user = { ...state.user, ...action.payload };
        
        // Update requestsRemaining if request_balance is present in the updated user
        if (action.payload.request_balance !== undefined) {
          state.requestsRemaining = action.payload.request_balance;
        }
        
        // Update local storage
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER,
          JSON.stringify(state.user)
        );
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.profileUpdateStatus = "failed";
        state.profileUpdateError = action.payload;
      });
  },
});

export const {
  logout,
  setConnected,
  addMessageToHistory,
  decrementRequestsRemaining,
  clearHistory,
  updateRequestsRemaining,
  clearProfileUpdateStatus,
} = authSlice.actions;

export default authSlice.reducer;