import axios from "axios";
import { useAuth } from "./authProvider";

const api = axios.create({
  baseURL: "https://api.codeai.studio/api",
});

// Create a custom hook to get the authenticated API instance
export const useAuthenticatedApi = () => {
  const { logout } = useAuth();

  // Add a response interceptor to handle 401 errors
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return api;
};

// Helper function to create headers with token
const createHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getUser = async (token) => {
  try {
    const response = await api.get("/user", createHeaders(token));
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const getSettings = async (token) => {
  try {
    const response = await api.get("/key/settings", createHeaders(token));
    return response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.status === 404 &&
      error.response.data === "Active subscription not found for this user."
    ) {
      return { error: "NO_ACTIVE_SUBSCRIPTION" };
    }
    console.error("Error fetching settings data:", error);
    throw error;
  }
};

export const setModel = async (token, model) => {
  try {
    const response = await api.post(
      "/key/token/set/model",
      { model },
      createHeaders(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error setting model:", error);
    throw error;
  }
};
