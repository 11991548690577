export const themes = {
  modern: {
    name: 'Modern',
    colors: {
      background: '#0f172a',
      text: '#e2e8f0',
      primary: '#8b5cf6',
      secondary: '#6366f1',
      accent: '#f472b6',
      surface: '#1e293b',
      hover: 'rgba(71, 85, 105, 0.5)',
      userMessage: 'rgba(139, 92, 246, 0.15)',
      aiMessage: 'rgba(99, 102, 241, 0.15)',
      userMessageBorder: 'rgba(139, 92, 246, 0.3)',
      aiMessageBorder: 'rgba(99, 102, 241, 0.3)',
      messageShadow: 'rgba(0, 0, 0, 0.1)',
      messageCTA: 'rgba(139, 92, 246, 0.2)',
      ctaText: '#e2e8f0',
      userGradientStart: '#8b5cf6',
      userGradientEnd: '#6366f1',
      aiGradientStart: '#6366f1',
      aiGradientEnd: '#f472b6',
    },
  },
  light: {
    name: 'Light',
    colors: {
      background: '#f8fafc',
      text: '#1e293b',
      primary: '#6366f1',
      secondary: '#3b82f6',
      accent: '#ec4899',
      surface: 'rgba(241, 245, 249, 1)',
      hover: 'rgba(226, 232, 240, 0.5)',
      // Improved contrast for better readability
      userMessage: 'rgba(99, 102, 241, 0.08)',
      aiMessage: 'rgba(59, 130, 246, 0.08)',
      userMessageBorder: 'rgba(99, 102, 241, 0.25)',
      aiMessageBorder: 'rgba(59, 130, 246, 0.25)',
      messageShadow: 'rgba(0, 0, 0, 0.05)',
      messageCTA: '#6366f1',
      ctaText: '#ffffff',
      userGradientStart: '#6366f1',
      userGradientEnd: '#818cf8',
      aiGradientStart: '#3b82f6',
      aiGradientEnd: '#60a5fa',
    },
  },
  dark: {
    name: 'Dark',
    colors: {
      background: '#020617',
      text: '#e2e8f0',
      primary: '#818cf8',
      secondary: '#60a5fa',
      accent: '#f472b6',
      surface: 'rgba(30, 41, 59, 1)',
      hover: 'rgba(51, 65, 85, 0.5)',
      userMessage: 'rgba(129, 140, 248, 0.12)',
      aiMessage: 'rgba(96, 165, 250, 0.12)',
      userMessageBorder: 'rgba(129, 140, 248, 0.25)',
      aiMessageBorder: 'rgba(96, 165, 250, 0.25)',
      messageShadow: 'rgba(0, 0, 0, 0.2)',
      messageCTA: 'rgba(129, 140, 248, 0.2)',
      ctaText: '#e2e8f0',
      userGradientStart: '#818cf8',
      userGradientEnd: '#6366f1',
      aiGradientStart: '#60a5fa',
      aiGradientEnd: '#f472b6',
    },
  },
  solarized: {
    name: 'Solarized',
    colors: {
      // Updated base colors for better contrast
      background: '#002b36',
      text: '#93a1a1', // Improved from #839496 for better readability
      primary: '#268bd2',
      secondary: '#2aa198',
      accent: '#d33682',
      surface: 'rgba(7, 54, 66, 1)',
      hover: 'rgba(0, 43, 54, 0.5)',
      // New message colors with Solarized palette
      userMessage: 'rgba(38, 139, 210, 0.1)',
      aiMessage: 'rgba(42, 161, 152, 0.1)',
      userMessageBorder: 'rgba(38, 139, 210, 0.25)',
      aiMessageBorder: 'rgba(42, 161, 152, 0.25)',
      messageShadow: 'rgba(0, 0, 0, 0.15)',
      messageCTA: '#268bd2',
      ctaText: '#fdf6e3', // Solarized light
      userGradientStart: '#268bd2',
      userGradientEnd: '#6c71c4',
      aiGradientStart: '#2aa198',
      aiGradientEnd: '#859900',
    },
  },
  // New theme: Ocean
  ocean: {
    name: 'Ocean',
    colors: {
      background: '#1a202c',
      text: '#e2e8f0',
      primary: '#00b4d8',
      secondary: '#0077b6',
      accent: '#48cae4',
      surface: 'rgba(26, 32, 44, 1)',
      hover: 'rgba(44, 82, 130, 0.5)',
      userMessage: 'rgba(0, 180, 216, 0.12)',
      aiMessage: 'rgba(0, 119, 182, 0.12)',
      userMessageBorder: 'rgba(0, 180, 216, 0.25)',
      aiMessageBorder: 'rgba(0, 119, 182, 0.25)',
      messageShadow: 'rgba(0, 0, 0, 0.15)',
      messageCTA: 'rgba(0, 180, 216, 0.2)',
      ctaText: '#e2e8f0',
      userGradientStart: '#00b4d8',
      userGradientEnd: '#0077b6',
      aiGradientStart: '#0077b6',
      aiGradientEnd: '#48cae4',
    },
  },
  // New theme: Forest
  forest: {
    name: 'Forest',
    colors: {
      background: '#1b2428',
      text: '#d5d8d8',
      primary: '#4ade80',
      secondary: '#22c55e',
      accent: '#86efac',
      surface: 'rgba(27, 36, 40, 1)',
      hover: 'rgba(34, 197, 94, 0.1)',
      userMessage: 'rgba(74, 222, 128, 0.12)',
      aiMessage: 'rgba(34, 197, 94, 0.12)',
      userMessageBorder: 'rgba(74, 222, 128, 0.25)',
      aiMessageBorder: 'rgba(34, 197, 94, 0.25)',
      messageShadow: 'rgba(0, 0, 0, 0.15)',
      messageCTA: 'rgba(74, 222, 128, 0.2)',
      ctaText: '#1b2428',
      userGradientStart: '#4ade80',
      userGradientEnd: '#22c55e',
      aiGradientStart: '#22c55e',
      aiGradientEnd: '#86efac',
    },
  },
  // New theme: Sunset
  sunset: {
    name: 'Sunset',
    colors: {
      background: '#1f1720',
      text: '#e2e8f0',
      primary: '#f59e0b',
      secondary: '#d97706',
      accent: '#fbbf24',
      surface: 'rgba(31, 23, 32, 1)',
      hover: 'rgba(217, 119, 6, 0.1)',
      userMessage: 'rgba(245, 158, 11, 0.12)',
      aiMessage: 'rgba(217, 119, 6, 0.12)',
      userMessageBorder: 'rgba(245, 158, 11, 0.25)',
      aiMessageBorder: 'rgba(217, 119, 6, 0.25)',
      messageShadow: 'rgba(0, 0, 0, 0.15)',
      messageCTA: 'rgba(245, 158, 11, 0.2)',
      ctaText: '#e2e8f0',
      userGradientStart: '#f59e0b',
      userGradientEnd: '#d97706',
      aiGradientStart: '#d97706',
      aiGradientEnd: '#fbbf24',
    },
  },
};