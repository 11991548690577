import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAgents,
  createNewAgent,
  updateExistingAgent,
  deleteExistingAgent,
  toggleStarAgent,
  getFavoriteAgents,
} from "../../../utils/api";

export const fetchAgents = createAsyncThunk(
  "agents/fetchAgents",
  async ({ type, token, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const data = await getAgents(token, type, page, limit);
      return { type, data, page, limit };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch agents");
    }
  }
);
export const createAgent = createAsyncThunk(
  "agents/createAgent",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const data = await createNewAgent(token, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to create agent");
    }
  }
);

export const updateAgent = createAsyncThunk(
  "agents/updateAgent",
  async ({ formData, token, assistantId }, { rejectWithValue }) => {
    try {
      const data = await updateExistingAgent(token, assistantId, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update agent");
    }
  }
);

export const deleteAgent = createAsyncThunk(
  "agents/deleteAgent",
  async ({ agentId, token }, { rejectWithValue }) => {
    try {
      await deleteExistingAgent(token, agentId);
      return agentId;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to delete agent");
    }
  }
);

export const searchAgents = createAsyncThunk(
  "agents/searchAgents",
  async ({ token, searchTerm }, { rejectWithValue }) => {
    try {
      const data = await searchAgents(token, searchTerm);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to search agents");
    }
  }
);

export const toggleAgentStar = createAsyncThunk(
  "agents/toggleStar",
  async ({ agentId, token, currentTab }, { rejectWithValue }) => {
    try {
      const data = await toggleStarAgent(token, agentId);
      return { agentId, isStarred: data.isStarred, currentTab };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to toggle star status"
      );
    }
  }
);

export const fetchFavoriteAgents = createAsyncThunk(
  "agents/fetchFavorites",
  async (token, { rejectWithValue }) => {
    try {
      const data = await getFavoriteAgents(token);
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch favorite agents"
      );
    }
  }
);

const agentsSlice = createSlice({
  name: "agents",
  initialState: {
    allAgents: {
      items: [],
      totalPages: 0,
      currentPage: 1,
    },
    myAgents: {
      items: [],
      totalPages: 0,
      currentPage: 1,
    },
    publicAgents: {
      items: [],
      totalPages: 0,
      currentPage: 1,
    },
    favoriteAgents: {
      items: [],
      totalPages: 0,
      currentPage: 1,
    },
    isLoading: false,
    error: null,
    selectedAgent: null,
  },
  reducers: {
    setSelectedAgent: (state, action) => {
      state.selectedAgent = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Agents
      .addCase(fetchAgents.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      // In the fetchAgents.fulfilled case
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        const { type, data, page } = action.payload;
        const { items, totalPages } = data;
        let targetAgents;
        switch (type) {
          case "my":
            targetAgents = state.myAgents;
            break;
          case "public":
            targetAgents = state.publicAgents;
            break;
          case "favorites":
            targetAgents = state.favoriteAgents;
            break;
          case "all":
          default:
            targetAgents = state.allAgents;
        }

        // Always replace the items with the new page's items
        targetAgents.items = items;
        targetAgents.totalPages = totalPages;
        targetAgents.currentPage = page;
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch agents";
      })
      // Create Agent
      .addCase(createAgent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        if (!state.myAgents.items) {
          state.myAgents.items = [];
        }
        state.myAgents.items.push(action.payload);
      })
      .addCase(createAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to create agent";
      })
      // Update Agent
      .addCase(updateAgent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.myAgents.items.findIndex(
          (agent) => agent.assistantId === action.payload.assistantId
        );
        if (index !== -1) {
          state.myAgents.items[index] = action.payload;
        }
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to update agent";
      })
      // Delete Agent
      .addCase(deleteAgent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.myAgents = state.myAgents.filter(
          (agent) => agent.assistantId !== action.payload
        );
      })
      .addCase(deleteAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to delete agent";
      })

      .addCase(toggleAgentStar.fulfilled, (state, action) => {
        if (!action.payload) {
          console.error("ToggleAgentStar: Payload is undefined");
          return;
        }

        const { agentId, isStarred, currentTab } = action.payload;

        if (!agentId) {
          console.error(
            "ToggleAgentStar: agentId is undefined",
            action.payload
          );
          return;
        }

        // Update agent in all lists to ensure consistency
        const updateAgentInList = (list) => {
          const index = list.items.findIndex((a) => a.assistantId === agentId);
          if (index !== -1) {
            list.items[index] = {
              ...list.items[index],
              isStarred,
              starCount: isStarred
                ? (list.items[index].starCount || 0) + 1
                : Math.max((list.items[index].starCount || 0) - 1, 0),
            };
          }
        };

        updateAgentInList(state.allAgents);
        updateAgentInList(state.myAgents);
        updateAgentInList(state.publicAgents);
        updateAgentInList(state.favoriteAgents);

        // If we're in the favorites tab and unstarring, remove the agent
        if (currentTab === "favorites" && !isStarred) {
          state.favoriteAgents.items = state.favoriteAgents.items.filter(
            (a) => a.assistantId !== agentId
          );
        }
      })
      .addCase(toggleAgentStar.rejected, (state, action) => {
        state.error = action.payload || "Failed to toggle star status";
      })
      .addCase(searchAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allAgents.items = action.payload;
      })
      // Fetch Favorite Agents
      .addCase(fetchFavoriteAgents.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFavoriteAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.favoriteAgents = action.payload;
      })
      .addCase(fetchFavoriteAgents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch favorite agents";
      });
  },
});

export const { setSelectedAgent, clearError } = agentsSlice.actions;
export default agentsSlice.reducer;
