import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  MenuIcon,
  ChevronDownIcon,
  PlusIcon,
  LogoutIcon,
  LockClosedIcon,
  SparklesIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { useAuth } from "../utils/authProvider";
import { models } from "../utils/models";
import { CheckIcon } from "lucide-react";

const Header = ({ toggleSidebar, toggleUpgradeModal }) => {
  const {
    token,
    user,
    logout,
    settings,
    currentModel,
    switchModel,
    clearHistory,
    requestsRemaining,
  } = useAuth();

  const defaultSettings = {
    plan: "Free",
    selectedModel: "gpt-3.5",
    requestsRemaining: 0,
  };

  const safeSettings = {
    ...defaultSettings,
    ...settings,
  };

  const safeCurrentModel = currentModel || defaultSettings.selectedModel;
  const safeRequestsRemaining =
    requestsRemaining ?? defaultSettings.requestsRemaining;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [modelDropdownVisible, setModelDropdownVisible] = useState(false);
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
  const userDropdownRef = useRef(null);
  const modelDropdownRef = useRef(null);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleModelDropdown = () =>
    setModelDropdownVisible(!modelDropdownVisible);

  const handleClickOutside = (event) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
    if (
      modelDropdownRef.current &&
      !modelDropdownRef.current.contains(event.target)
    ) {
      setModelDropdownVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  React.useEffect(() => {
    if (!token) logout();
  }, [token, logout]);

  const dropdownVariants = {
    hidden: { opacity: 0, y: -10, scale: 0.95 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  const isFreePlan = ["free", "explorer"].includes(
    safeSettings.plan.toLowerCase()
  );

  const handleModelSwitch = (model) => {
    if (isFreePlan && model.toLowerCase() !== "gpt-3.5") {
      toggleUpgradeModal();
    } else {
      switchModel(model);
      toggleModelDropdown();
    }
  };

  const renderUpgradeButton = () => (
    <motion.button
      variants={buttonVariants}
      initial="rest"
      whileHover="hover"
      whileTap="tap"
      onClick={() => {
        toggleDropdown();
        toggleUpgradeModal();
      }}
      className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-lg px-4 py-2 text-sm font-medium hover:from-purple-600 hover:to-pink-600 transition duration-200 flex items-center justify-center shadow-md"
    >
      <SparklesIcon className="h-4 w-4 mr-2" />
      <span>Upgrade to Pro</span>
    </motion.button>
  );

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="sticky top-0 z-50 bg-gradient-to-r from-slate-900 via-slate-800 to-slate-900 backdrop-blur-lg border-b border-slate-700/50 text-white shadow-lg"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            {user && (
              <motion.button
                variants={buttonVariants}
                initial="rest"
                whileHover="hover"
                whileTap="tap"
                onClick={toggleSidebar}
                className="text-white mr-4 p-2 rounded-full bg-slate-700/50 hover:bg-slate-600/50 transition-colors duration-200"
              >
                <MenuIcon className="h-5 w-5" />
              </motion.button>
            )}
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-white font-spac3 text-xl md:text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent"
            >
              CodeAI
            </motion.h2>
          </div>
          <div className="flex items-center space-x-2 sm:space-x-4">
            {user && (
              <motion.button
                variants={buttonVariants}
                initial="rest"
                whileHover="hover"
                whileTap="tap"
                onClick={clearHistory}
                className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-full px-2 py-2 sm:px-4 sm:py-2 text-sm font-medium hover:from-blue-600 hover:to-indigo-700 transition duration-200 flex items-center shadow-md"
              >
                <PlusIcon className="h-4 w-4 sm:mr-2" />
                <span className="hidden sm:inline">New Chat</span>
              </motion.button>
            )}
            {settings ? (
              <div className="relative" ref={modelDropdownRef}>
                <motion.button
                  variants={buttonVariants}
                  initial="rest"
                  whileHover="hover"
                  whileTap="tap"
                  onClick={toggleModelDropdown}
                  className="bg-slate-700/50 text-white rounded-full px-2 py-2 sm:px-4 sm:py-2 text-sm font-medium hover:bg-slate-600/50 transition duration-200 flex items-center shadow-md"
                >
                  <img
                    src={
                      models.find(
                        (m) =>
                          m.name.toLowerCase() ===
                          safeCurrentModel.toLowerCase()
                      )?.logo
                    }
                    alt="Model Logo"
                    className="h-5 w-5 bg-white rounded-full p-0.5"
                  />
                  <span className="hidden sm:inline ml-2">
                    {safeCurrentModel.toUpperCase()}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 ml-1 sm:ml-2" />
                </motion.button>
                <AnimatePresence>
                  {modelDropdownVisible && (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={dropdownVariants}
                      transition={{ duration: 0.2 }}
                      className="absolute right-0 mt-2 w-56 bg-slate-800/90 backdrop-blur-md rounded-lg shadow-lg border border-slate-700/50 overflow-hidden"
                    >
                      {models.map((model) => (
                        <motion.button
                          key={model.name}
                          onClick={() => handleModelSwitch(model.name)}
                          className={`flex items-center justify-between w-full px-4 py-2 text-sm transition-colors duration-200 ${
                            isFreePlan && model.name.toLowerCase() !== "gpt-3.5"
                              ? "text-slate-500 cursor-not-allowed"
                              : "hover:bg-slate-700/50 text-white"
                          }`}
                          disabled={
                            isFreePlan && model.name.toLowerCase() !== "gpt-3.5"
                          }
                        >
                          <div className="flex items-center">
                            <img
                              src={model.logo}
                              alt={`${model.name} Logo`}
                              className={`h-5 w-5 mr-2 rounded-full ${
                                isFreePlan &&
                                model.name.toLowerCase() !== "gpt-3.5"
                                  ? "opacity-50"
                                  : ""
                              }`}
                            />
                            <span>{model.name.toUpperCase()}</span>
                          </div>
                          {isFreePlan &&
                            model.name.toLowerCase() !== "gpt-3.5" && (
                              <LockClosedIcon className="h-4 w-4 text-slate-500" />
                            )}
                          {safeCurrentModel &&
                            model.name.toLowerCase() ===
                              safeCurrentModel.toLowerCase() && (
                              <CheckIcon className="h-4 w-4 text-green-500" />
                            )}
                        </motion.button>
                      ))}
                      {isFreePlan && (
                        <div className="px-4 py-2 text-xs text-slate-400 border-t border-slate-700/50">
                          <button
                            onClick={() => {
                              toggleModelDropdown();
                              toggleUpgradeModal();
                            }}
                            className="text-blue-400 hover:underline"
                          >
                            Upgrade to access more models
                          </button>
                        </div>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ) : (
              renderUpgradeButton()
            )}
            {user && (
              <div className="relative" ref={userDropdownRef}>
                <motion.button
                  variants={buttonVariants}
                  initial="rest"
                  whileHover="hover"
                  whileTap="tap"
                  onClick={toggleDropdown}
                  className="bg-slate-700/50 text-white rounded-full px-2 py-2 sm:px-4 sm:py-2 text-sm font-medium hover:bg-slate-600/50 transition duration-200 flex items-center shadow-md"
                >
                  <UserCircleIcon className="h-5 w-5 sm:mr-2" />
                  <span className="hidden sm:inline">
                    {user
                      ? `${user.first_name || ""} ${user.last_name || ""}`
                      : "Guest User"}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 ml-1 sm:ml-2" />
                </motion.button>
                <AnimatePresence>
                  {dropdownVisible && (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={dropdownVariants}
                      transition={{ duration: 0.2 }}
                      className="absolute right-0 mt-2 w-64 bg-slate-800/90 backdrop-blur-md rounded-lg shadow-lg border border-slate-700/50 overflow-hidden"
                    >
                      <div className="p-4 space-y-3">
                        <div className="flex flex-wrap gap-2">
                          <span className="inline-block bg-slate-700/50 rounded-full px-3 py-1 text-xs font-semibold">
                            Plan: {safeSettings.plan}
                          </span>
                          <span className="inline-block bg-slate-700/50 rounded-full px-3 py-1 text-xs font-semibold">
                            Requests: {safeRequestsRemaining}
                          </span>
                        </div>
                        {isFreePlan && renderUpgradeButton()}

                        <motion.button
                          whileHover={{
                            backgroundColor: "rgba(75, 85, 99, 0.5)",
                          }}
                          onClick={() => {
                            logout();
                            toggleDropdown();
                          }}
                          className="w-full text-left px-4 py-2 text-sm hover:bg-slate-700/50 transition-colors duration-200 rounded-lg flex items-center"
                        >
                          <span className="flex-1">Log Out</span>
                          <LogoutIcon className="h-4 w-4" />
                        </motion.button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
